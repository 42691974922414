import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Common/Button/Button";
import style from "./IntendingSite.module.css";

const IntendingSite: React.FC = (): JSX.Element => {
  return (
    <section id="intendingSite" className={style.intendingSite}>
      <div className="container">
        <div className={style.wrap}>
          <div className={style.textWrap}>
            <h2 className={style.title}>Про проєкт</h2>
            <div className={style.text}>
              <p>
                Проєкт створено як сайт-меморіал пам’яті військовослужбовців,
                які загинули під час широкомасштабного вторгнення росії в
                Україну.
              </p>
              <p>
                Вони пожертвували найдорожчим – своїм життям, щоб дати нам шанс
                на майбутнє!
              </p>
              <p>
                Наша ціль - зберегти пам’ять про них, дати змогу близьким та
                рідним відобразити їхні історії життя до війни, та під час
                військової агресії. <br />
                Вічна пам’ять героям!
              </p>
            </div>
            <Link to="/about" className={style.btn}>
              <Button title="Дізнатись більше" />
            </Link>
          </div>
          <div className={style.backgroundWrapper}></div>
        </div>
      </div>
    </section>
  );
};

export default IntendingSite;
