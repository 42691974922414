import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { signTerms } from "../../../redux/User/slices/SignUpSlice";
import Button from "../../Common/Button/Button";
import { PolicyModalProps } from "./Modal.types";
import Terms from "../../User/Policy/Terms";
import style from "./Modal.module.css";

const TermsModal: React.FC<PolicyModalProps> = ({ modalIsOpen }) => {
  const dispatch: Dispatch = useDispatch();

  return (
    <div className={style.modal}>
      <div className={style.policyContentWrap}>
        <h2 className={style.title}>Правила користування сайтом</h2>
        <div className={style.content}>
          <Terms modal={true} />
        </div>
      </div>
      <div
        onClick={() => {
          dispatch(signTerms(true));
          modalIsOpen();
        }}
      >
        <Button title={"Погоджуюсь"} modal />
      </div>
    </div>
  );
};

export default TermsModal;
