import React from "react";
import icons from "../../../assets/icons.svg";
import { ButtonCloseModalType } from "./ButtonCloseModal.types";
import style from "./ButtonCloseModal.module.css";

const ButtonCloseModal: React.FC<ButtonCloseModalType> = ({
  openModalWrapperLogic,
  setIsLoginModalOpen,
  policyAndTermsLogic,
  R40,
  white,
  className,
}) => {
  const closeModal = () => {
    if (policyAndTermsLogic) {
      policyAndTermsLogic();
    } else {
      setIsLoginModalOpen(false);
      openModalWrapperLogic(false);
      document.body.classList.remove("lock");
    }
  };

  return (
    <button
      className={`${style.closeIcon} ${className} 
      ${R40 && style.closeIconR40} 
      ${white && style.closeIconWhite}`}
      onClick={closeModal}
    >
      <svg className={style.icon} width={24} height={24}>
        <use href={`${icons}#close`} />
      </svg>
    </button>
  );
};

export default ButtonCloseModal;
