import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/User/types";
import { signPolicy, signTerms } from "../../../redux/User/slices/SignUpSlice";
import { PolicyAndTermsProps } from "./CustomCheckbox.types";
import icons from "../../../assets/icons.svg";
import style from "./CustomCheckbox.module.css";

const CustomCheckbox: React.FC<PolicyAndTermsProps> = ({
  policyModalOpen,
  termsModalOpen,
  policyError,
  termsError,
  policyCheckbox,
  termsCheckbox,
  setTermsCheckbox,
  setPolicyCheckbox,
  activeBtnColor,
}) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <div className={style.checkboxLabel}>
      <div
        className={style.checkboxWrapper}
        style={{ fontSize: activeBtnColor ? "16px" : "" }}
      >
        {policyCheckbox ? (
          <svg
            className={
              activeBtnColor
                ? style.customCheckboxWhite
                : style.customCheckboxGreen
            }
            width={24}
            height={24}
            onClick={() => {
              dispatch(signPolicy(!policyCheckbox));
              setPolicyCheckbox(!policyCheckbox);
            }}
          >
            <use href={`${icons}#checkbox_active`} />
          </svg>
        ) : (
          <svg
            className={
              policyError
                ? style.customErrorCheckbox
                : style.customEmptyCheckbox
            }
            width={24}
            height={24}
            onClick={() => {
              dispatch(signPolicy(!policyCheckbox));
              setPolicyCheckbox(!policyCheckbox);
            }}
          >
            <use href={`${icons}#checkbox`} />
          </svg>
        )}
        <p>
          Погоджуюсь з
          <button
            type="button"
            onClick={() => policyModalOpen(true)}
            className={`${style.navigateBtn} ${style.specialNavigateLink}`}
            style={{ fontSize: activeBtnColor ? "16px" : "" }}
          >
            політикою конфіденційності
          </button>
        </p>
      </div>
      <div
        className={style.checkboxWrapper}
        style={{ fontSize: activeBtnColor ? "16px" : "" }}
      >
        {termsCheckbox ? (
          <svg
            className={
              activeBtnColor
                ? style.customCheckboxWhite
                : style.customCheckboxGreen
            }
            width={24}
            height={24}
            onClick={() => {
              dispatch(signTerms(!termsCheckbox));
              setTermsCheckbox(!termsCheckbox);
            }}
          >
            <use href={`${icons}#checkbox_active`} />
          </svg>
        ) : (
          <svg
            className={
              termsError ? style.customErrorCheckbox : style.customEmptyCheckbox
            }
            width={24}
            height={24}
            onClick={() => {
              dispatch(signTerms(!termsCheckbox));
              setTermsCheckbox(!termsCheckbox);
            }}
          >
            <use href={`${icons}#checkbox`} />
          </svg>
        )}
        <p>
          Погоджуюсь з
          <button
            type="button"
            onClick={() => {
              termsModalOpen(true);
            }}
            className={`${style.navigateBtn} ${style.specialNavigateLink}`}
            style={{ fontSize: activeBtnColor ? "16px" : "" }}
          >
            правилами користування сайтом
          </button>
        </p>
      </div>
    </div>
  );
};

export default CustomCheckbox;
