import Hero from "../../../components/User/Hero/Hero";
import StepsInfo from "../../../components/User/stepsInfo/StepsInfo";
import IntendingSite from "../../../components/User/intendingSite/IntendingSite";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <IntendingSite />
      <StepsInfo />
    </>
  );
};

export default Home;
