import React, { useState } from "react";
import { useEffect } from "react";
import LoadingBar from "react-top-loading-bar";

const Loader: React.FC = (): JSX.Element => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    setProgress(100);
  }, []);

  return (
    <div>
      <LoadingBar
        color="#907fe5"
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    </div>
  );
};

export default Loader;
