import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/User/types";
import { getSignUpData, getUser } from "../../../redux/User/selectors";
import {
  addLastName,
  addMiddleName,
  addName,
} from "../../../redux/User/slices/SignUpSlice";
import { namePattern } from "../../../constants/patterns";
import { NameProps } from "./Input.types";
import style from "./Input.module.css";

const NameInput: React.FC<NameProps> = ({
  profile,
  title,
  fieldName,
  signUp,
  success,
  empty,
  setEmpty,
  setServerError,
}) => {
  const signUpData = useSelector(getSignUpData);
  const { user } = useSelector(getUser);
  const dispatch: AppDispatch = useDispatch();

  const errorColor = profile ? "#CA0000" : "#86181e";

  const saveData = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "firstName":
        return addName(value);
      case "middleName":
        return addMiddleName(value);
      default:
        return addLastName(value);
    }
  };

  const addedCurrentData = (fieldName: string) => {
    switch (fieldName) {
      case "firstName":
        return profile ? user.firstName : signUpData.firstName;
      case "middleName":
        return profile ? user.middleName : signUpData.middleName;
      default:
        return profile ? user.lastName : signUpData.lastName;
    }
  };

  const [name, setName] = useState<string>(addedCurrentData(fieldName));
  const [nameValidateError, setNameValidateError] = useState(false);
  const [nameLength, setNameLength] = useState(false);
  const [first, setFirst] = useState(true);

  const handleNameSuccessValidate = useCallback(() => {
    setNameLength(false);
    setEmpty(false);
    setNameValidateError(false);
    success(false);
  }, [setEmpty, success]);

  const handleNameValidate = useCallback(
    (value: string) => {
      if (value === "") {
        success(true);
        setEmpty(true);
        setNameValidateError(false);
        setNameLength(false);
        return;
      } else {
        handleNameSuccessValidate();
      }

      const isValidName = namePattern.test(value);

      if (!isValidName && value) {
        success(true);
        setEmpty(false);
        setNameValidateError(true);
        setNameLength(false);
        return;
      } else {
        handleNameSuccessValidate();
      }

      if (value.length > 50) {
        success(true);
        setEmpty(false);
        setNameValidateError(false);
        setNameLength(true);
      } else {
        handleNameSuccessValidate();
      }
    },
    [handleNameSuccessValidate, setEmpty, success]
  );

  useEffect(() => {
    if (profile && first) {
      success(false);
    }
    if (signUpData[`${fieldName}`]) {
      handleNameValidate(signUpData[`${fieldName}`]);
    }
  }, [handleNameValidate, signUpData, profile, success, first, fieldName]);

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (signUp) dispatch(saveData(fieldName, e.target.value));
    setFirst(false);
    setName(e.target.value);
    handleNameValidate(e.target.value);
  };

  return (
    <>
      <label
        htmlFor={fieldName}
        className={style.formLabel}
        style={{ color: profile ? "#ffffff" : "#03051e" }}
      >
        {title
          ? `${title}${profile ? "*" : ""}`
          : `По батькові${profile ? "*" : ""}`}
        <input
          autoComplete="on"
          id={fieldName}
          className={
            profile
              ? `${style.formInput} ${style.profileInput}`
              : style.formInput
          }
          type="text"
          name={fieldName}
          value={name}
          placeholder={`Введіть ${
            title ? `своє ${title.toLocaleLowerCase()}` : "по батькові"
          }`}
          onChange={(e) => {
            setServerError(false);
            handleNameInputChange(e);
          }}
          style={
            nameValidateError || empty || nameLength
              ? { borderColor: errorColor, color: errorColor }
              : name !== "" && !nameValidateError && !nameLength
              ? { borderColor: profile ? "#ffffff" : "#009955" }
              : { borderColor: profile ? "#ffffff" : "#93999b" }
          }
        />
      </label>
      {empty && (
        <p className={style.errorMessage} style={{ color: errorColor }}>
          Це поле є обов'язковим
        </p>
      )}
      {nameValidateError && (
        <p className={style.errorMessage} style={{ color: errorColor }}>
          Тільки кирилиця
        </p>
      )}
      {nameLength && (
        <p className={style.errorMessage} style={{ color: errorColor }}>
          Не більше 50 символів
        </p>
      )}
    </>
  );
};

export default NameInput;
