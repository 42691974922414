import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentEmail } from "../../../redux/User/selectors";
import { updateHasPassword } from "../../../redux/User/slices/UserSlice";
import { createNewPass } from "../../../redux/User/operations";
import {
  AppDispatch,
  CreateNewPassServesType,
} from "../../../redux/User/types";
import {
  includeNumberPattern,
  passwordPattern,
  upperCasePattern,
} from "../../../constants/patterns";
import Button from "../../Common/Button/Button";
import Loading from "../../Common/Loading/Loading";
import ServerError from "../../Common/ServerError/ServerError";
import icons from "../../../assets/icons.svg";
import { CreateServicesPassProp } from "./Modal.types";
import style from "./Modal.module.css";

const CreateNewPasswordServicesModal: React.FC<CreateServicesPassProp> = ({
  createServicePassHandler,
  changedPassModalHandler,
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const email = useSelector(getCurrentEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [passVisability, setPassVisability] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [emptyPassError, setEmptyPassError] = useState(false);
  const [passLatinError, setPassLatinError] = useState(false);
  const [passIncludeNumberError, setPassIncludeNumberError] = useState(false);
  const [passIncCapLettersError, setPassIncCapLettersError] = useState(false);
  const [repeatPassVisability, setrepeatPassVisability] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPassError, setRepeatPassError] = useState(false);
  const [emptyRepeatPassError, setEmptyRepeatPassError] = useState(false);

  // validate password

  const validatePassword = (value: string) => {
    setEmptyPassError(false);
    if (value !== repeatPassword && repeatPassword) {
      setPasswordError(true);
      setRepeatPassError(true);
    } else {
      setRepeatPassError(false);
    }
    if (value.length < 8) {
      setPasswordError(true);
    }

    const isValidPass = passwordPattern.test(value);
    if (!isValidPass) {
      setPassLatinError(true);
      setPasswordError(true);
    } else {
      setPassLatinError(false);
    }
    const isIncludeNumber = includeNumberPattern.test(value);
    if (!isIncludeNumber) {
      setPassIncludeNumberError(true);
      setPasswordError(true);
    } else {
      setPassIncludeNumberError(false);
    }
    const inCapitalLetters = upperCasePattern.test(value);
    if (!inCapitalLetters) {
      setPassIncCapLettersError(true);
      setPasswordError(true);
    } else {
      setPassIncCapLettersError(false);
    }
    if (value === "") {
      setPasswordError(false);
    }
    if (
      value.length >= 8 &&
      email !== value &&
      isValidPass &&
      isIncludeNumber &&
      inCapitalLetters
    ) {
      setPasswordError(false);
    }
  };

  const validateRepeatPass = (value: string) => {
    if (password !== value) {
      setRepeatPassError(true);
    } else {
      setRepeatPassError(false);
    }
    if (value === "") {
      setRepeatPassError(false);
    }
    setEmptyRepeatPassError(false);
  };

  // submit

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const passwords: CreateNewPassServesType = {
      password: formData.get("newPassword") as string,
      confirmPassword: formData.get("confirmPassword") as string,
    };

    if (password === "") setEmptyPassError(true);
    if (repeatPassword === "") setEmptyRepeatPassError(true);

    if (
      password &&
      repeatPassword &&
      !emptyPassError &&
      !emptyRepeatPassError &&
      !passwordError &&
      !repeatPassError
    ) {
      submitForm(passwords);
    }
  };

  const submitForm = async (data: CreateNewPassServesType) => {
    setIsLoading(true);
    setServerError(false);
    try {
      const { payload } = await dispatch(createNewPass(data));
      if (payload !== 200) {
        setServerError(true);
        return;
      }
      dispatch(updateHasPassword(true));
      createServicePassHandler(false);
      changedPassModalHandler(true);
    } catch (e: any) {
      setServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.modal}>
        <div className={style.forgottenPassContentWrap}>
          <h2 className={`${style.title}`}>Створити пароль</h2>
          <div className={style.forggotenPassText}>
            <p>Створіть новий пароль для вашого профілю.</p>
          </div>
          <form
            className={`${style.form} ${style.newPassForm}`}
            onSubmit={handleSubmitForm}
          >
            <ul className={`${style.inputList} ${style.newPasswordList}`}>
              {/* new password */}

              <li className={style.inputItem}>
                <label htmlFor="newPassword" className={style.formLabel}>
                  Пароль
                  <input
                    id="newPassword"
                    autoComplete="on"
                    className={style.formInput}
                    type={passVisability ? "text" : "password"}
                    name="newPassword"
                    placeholder="Пароль має містити від 8 до 20 символів"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value.trim());
                      validatePassword(e.target.value.trim());
                    }}
                    style={
                      emptyPassError || passwordError
                        ? { borderColor: "#681318", color: "#86181e" }
                        : password !== "" && password
                        ? { borderColor: "#009955" }
                        : { borderColor: "#93999b" }
                    }
                  />
                  <div className={`${style.icon} ${style.backIcon} `}>
                    {!passVisability ? (
                      <svg
                        className={`${style.icon} ${style.activeIcon}`}
                        onClick={() => setPassVisability(!passVisability)}
                        width={20}
                        height={20}
                      >
                        <use href={`${icons}#eye_close`} />
                      </svg>
                    ) : (
                      <svg
                        className={`${style.icon} ${style.activeIcon}`}
                        onClick={() => setPassVisability(!passVisability)}
                        width={20}
                        height={20}
                      >
                        <use href={`${icons}#eye_open`} />
                      </svg>
                    )}
                  </div>
                </label>
                {emptyPassError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}
              </li>

              {/* repeat password */}

              <li className={style.inputItem}>
                <label htmlFor="repeatPassword" className={style.formLabel}>
                  Повторити пароль
                  <input
                    id="confirmPassword"
                    disabled={
                      passwordError || password.length === 0 ? true : false
                    }
                    autoComplete="on"
                    className={style.formInput}
                    type={repeatPassVisability ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Пароль має містити від 8 до 20 символів"
                    value={repeatPassword}
                    onChange={(e) => {
                      setRepeatPassword(e.target.value.trim());
                      validateRepeatPass(e.target.value.trim());
                    }}
                    style={
                      passwordError
                        ? { borderColor: "gray" }
                        : emptyRepeatPassError || repeatPassError
                        ? { borderColor: "#681318", color: "#86181e" }
                        : repeatPassword
                        ? { borderColor: "#009955" }
                        : { borderColor: "#93999b" }
                    }
                  />
                  <div className={`${style.icon} ${style.backIcon} `}>
                    {!repeatPassVisability ? (
                      <svg
                        className={`${style.icon} ${style.activeIcon}`}
                        onClick={() =>
                          setrepeatPassVisability(!repeatPassVisability)
                        }
                        width={20}
                        height={20}
                      >
                        <use href={`${icons}#eye_close`} />
                      </svg>
                    ) : (
                      <svg
                        className={`${style.icon} ${style.activeIcon}`}
                        onClick={() =>
                          setrepeatPassVisability(!repeatPassVisability)
                        }
                        width={20}
                        height={20}
                      >
                        <use href={`${icons}#eye_open`} />
                      </svg>
                    )}
                  </div>
                </label>
                {emptyRepeatPassError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}

                {/* errors list */}

                {password && (
                  <ul className={style.errorsList}>
                    <li
                      className={
                        !repeatPassError && repeatPassword.length !== 0
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      Підтвердіть пароль
                    </li>
                    <li
                      className={
                        !passIncludeNumberError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      Пароль має містити мінімум одну цифру
                    </li>

                    <li
                      className={
                        !passIncCapLettersError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      Пароль має містити мінімум одну велику літеру
                    </li>

                    <li
                      className={
                        password.length < 8 || password.length > 20
                          ? `${style.errorItem} ${style.error}`
                          : `${style.errorItem}`
                      }
                    >
                      Пароль має містити від 8 до 20 символів
                    </li>
                    <li
                      className={
                        !passLatinError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      Пароль має бути латиницею (a-z)
                    </li>
                  </ul>
                )}
              </li>
            </ul>
            <Button modal title={"Створити пароль"} />
            {serverError && <ServerError />}
          </form>
        </div>
      </div>
    </>
  );
};
export default CreateNewPasswordServicesModal;
