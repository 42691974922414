import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAllMemoryPages, getMemoryPageById } from "../../operations";
import {
  MediaType,
  Page,
  PageResponse,
  initialStateType,
} from "./MemotyPagesSlice.types";
import { getPageByIdForAdmin } from "../../../Admin/operations";

const initialState: initialStateType = {
  userPages: [],
  editPage: [],
  showPage: {
    status: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    deathDate: "",
    burialPlace: "",
    rank: "",
    callsign: "",
    dutyStation: "",
    mainPhoto: [
      {
        public_id: "",
        url: "",
      },
    ],
    document: [
      {
        public_id: "",
        url: "",
      },
    ],
    owner: "",
    _id: "",
    createdAt: "",
    updatedAt: "",
  },
};

const MemoryPagesSlice = createSlice({
  name: "MemoryPages",
  initialState,
  reducers: {
    createTemplateMemoryPage(state, action: PayloadAction<Page>) {
      state.editPage[0] = action.payload;
    },
    deletePage(state, action: PayloadAction<string>) {
      state.userPages = state.userPages.filter(
        (page) => page._id !== action.payload
      );
    },
    addAvatar(state, action: PayloadAction<MediaType>) {
      state.editPage[0].mainPhoto[0] = action.payload;
    },
    removeAvatar(state) {
      state.editPage[0].mainPhoto = [];
    },
    addReward(state, action: PayloadAction<string>) {
      state.editPage[0].award.push(action.payload);
    },
    editReward(
      state,
      action: PayloadAction<{ ind: number | null; value: string }>
    ) {
      const { ind, value } = action.payload;
      if (ind !== null) {
        state.editPage[0].award[ind] = value;
      }
    },
    removeReward(state, action: PayloadAction<number>) {
      state.editPage[0].award = state.editPage[0].award.filter(
        (_, ind) => ind !== action.payload
      );
    },
    addPhoto(state, action: PayloadAction<MediaType>) {
      state.editPage[0].photo.push(action.payload);
    },
    removePhoto(state, action: PayloadAction<number>) {
      state.editPage[0].photo = state.editPage[0].photo.filter(
        (_, ind) => ind !== action.payload
      );
    },
    addVideo(state, action: PayloadAction<string>) {
      state.editPage[0].videoLinks.push(action.payload);
    },
    removeVideo: (state, action: PayloadAction<number>) => {
      state.editPage[0].videoLinks = state.editPage[0].videoLinks.filter(
        (_, ind) => ind !== action.payload
      );
    },
    addDocument(state, action: PayloadAction<MediaType>) {
      state.editPage[0].document.push(action.payload);
    },
    removeDocument(state, action: PayloadAction<number>) {
      state.editPage[0].document = state.editPage[0].document.filter(
        (_, ind) => ind !== action.payload
      );
    },
    chooseEducation(state, action: PayloadAction<string>) {
      state.editPage[0].education = action.payload;
    },
    chooseMerriedStatus(state, action: PayloadAction<string>) {
      state.editPage[0].merriedStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMemoryPageById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.showPage = action.payload;
        }
      )
      .addCase(
        getPageByIdForAdmin.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.showPage = action.payload;
        }
      )
      .addCase(
        getAllMemoryPages.fulfilled,
        (state, action: PayloadAction<PageResponse[]>) => {
          state.userPages = action.payload;
        }
      );
  },
});

export const MemoryPages = MemoryPagesSlice.reducer;
export const {
  addVideo,
  removeVideo,
  addReward,
  editReward,
  removeReward,
  addPhoto,
  removePhoto,
  addAvatar,
  removeAvatar,
  removeDocument,
  addDocument,
  chooseEducation,
  chooseMerriedStatus,
  createTemplateMemoryPage,
  deletePage,
} = MemoryPagesSlice.actions;
