import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserInfoType } from "../../User/types";

const initialState: UserInfoType = {
  email: "",
};

const CheckUserEmail = createSlice({
  name: "checkUserEmail",
  initialState,
  reducers: {
    getUserEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
  },
});

export const CheckUserEmailReducer = CheckUserEmail.reducer;
export const { getUserEmail } = CheckUserEmail.actions;
