import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getUserEmail } from "../../../redux/User/slices/CheckUserEmail";
import {
  addEmail,
  addLastName,
  addMiddleName,
  addName,
  addPhone,
  addTimer,
  signPolicy,
  signTerms,
} from "../../../redux/User/slices/SignUpSlice";
import { AppDispatch, LogInRequest } from "../../../redux/User/types";
import { userLogIn, userResendVerify } from "../../../redux/User/operations";
import ServiseLogin from "./ServiceLogin/ServiceLogin";
import Button from "../../Common/Button/Button";
import Loading from "../../Common/Loading/Loading";
import ServerError from "../../Common/ServerError/ServerError";
import EmailInput from "../InputFields/EmailInput";
import PasswordInput from "../InputFields/PasswordInput";
import { ModalLoginProps } from "./Modal.types";
import style from "./Modal.module.css";

const LoginModal: React.FC<ModalLoginProps> = ({
  signUpModalHandler,
  loginModalHandler,
  forgetPassModalHandler,
  modalWrapperIsOpen,
  completeSingUpHandler,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(true);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [userError, setUserError] = useState<number | null>(null);
  const [serverError, setServerError] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const handleLoginBtnClick = () => {
    loginModalHandler(false);
    signUpModalHandler(true);
  };

  const fogetPasswordClick = () => {
    if (forgetPassModalHandler) {
      forgetPassModalHandler(true);
      loginModalHandler(false);
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const data: LogInRequest = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
    };

    setEmptyEmail(!data.email);
    setEmptyPassword(!data.password);

    if (
      data.email &&
      data.password &&
      !userError &&
      !emailError &&
      !passwordError &&
      !serverError
    ) {
      handleUserLogin(data);
    }
  };

  const handleUserLogin = async (data: LogInRequest) => {
    setIsLoading(true);
    const { payload } = await dispatch(userLogIn(data));
    if (
      payload !== null &&
      typeof payload === "object" &&
      "verify" in payload
    ) {
      if (payload.verify) {
        handleUserLoginSuccess();
      } else {
        handleUserVerifyError(data.email);
      }
      return;
    }

    if (payload === 401) {
      setUserError(payload);
    } else {
      setServerError(true);
    }
    setIsLoading(false);
  };

  const handleUserLoginSuccess = () => {
    dispatch(addTimer(0));
    dispatch(addName(""));
    dispatch(addLastName(""));
    dispatch(addMiddleName(""));
    dispatch(addPhone(""));
    dispatch(addEmail(""));
    dispatch(signPolicy(false));
    dispatch(signTerms(false));
    modalWrapperIsOpen(false);
    document.body.classList.remove("lock");
    loginModalHandler(false);
    navigate("/user/profile");
  };

  const handleUserVerifyError = (email: string) => {
    dispatch(getUserEmail(email));
    dispatch(userResendVerify({ email }));
    dispatch(addTimer(0));
    loginModalHandler(false);
    completeSingUpHandler(true);
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.modal}>
        <div className={style.loginContentWrap}>
          <h2 className={style.title}>Вхід</h2>
          <form noValidate className={style.form} onSubmit={handleSubmitForm}>
            <ul className={style.inputList}>
              <li className={style.inputItem}>
                <EmailInput
                  userError={userError}
                  setEmptyEmail={setEmptyEmail}
                  errorText="Дані введені невірно"
                  success={setEmailError}
                  emptyEmail={emptyEmail}
                  setUserError={setUserError}
                  setServerError={setServerError}
                />
              </li>
              <li className={style.inputItem}>
                <PasswordInput
                  userError={userError}
                  setUserError={setUserError}
                  success={setPasswordError}
                  emptyPassword={emptyPassword}
                  setEmptyPassword={setEmptyPassword}
                  setServerError={setServerError}
                />
              </li>
            </ul>
            <button
              type="button"
              className={style.navigateBtn}
              onClick={fogetPasswordClick}
              style={{ marginBottom: "32px" }}
            >
              Забули пароль?
            </button>
            <Button title={"Увійти"} modal />
            {serverError && <ServerError />}
          </form>
          <ServiseLogin />
          <div className={style.modalBottomInfo}>
            <p>Ще не маєте існуючого профілю?</p>
            <button onClick={handleLoginBtnClick} className={style.navigateBtn}>
              Зареєструватись
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
