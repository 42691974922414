import React from "react";
import { RemoveMemoryPageType } from "./Modal.types";
import Button from "../../Common/Button/Button";
import style from "./Modal.module.css";

const RemoveMemoryPageModal: React.FC<RemoveMemoryPageType> = ({
  removeMemoryPage,
}) => {
  return (
    <div className={style.modal}>
      <div className={style.checkEmailContentWrap}>
        <h2 className={`${style.title} ${style.titleRemoveMemoryPage}`}>
          Ви дійсно бажаєте видалити сторінку пам’яті?
        </h2>
        <div onClick={removeMemoryPage}>
          <Button title="видалити" modal />
        </div>
      </div>
    </div>
  );
};

export default RemoveMemoryPageModal;
