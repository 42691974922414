import React from "react";
import Button from "../../Common/Button/Button";
import { ModalNewVerificationCodeProps } from "./Modal.types";
import style from "./Modal.module.css";

const NewVerificationCode: React.FC<ModalNewVerificationCodeProps> = ({
  getNewVerificationCode,
  newPassModalHandler,
  endSingUpHundler,
  isChangeModalKey,
}): JSX.Element => {
  const openModal = () => {
    getNewVerificationCode(false);
    if (isChangeModalKey) {
      newPassModalHandler(true);
    } else {
      endSingUpHundler(true);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.successModalWrap}>
        <h2 className={`${style.title}`}>Kод надіслано повторно </h2>
        <p className={style.forggotenPassText}>
          Будь-ласка, перевірте свою пошту
        </p>
        <div onClick={openModal}>
          <Button title={"Ввести код"} modal />
        </div>
      </div>
    </div>
  );
};

export default NewVerificationCode;
