import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTimer } from "../../../redux/User/slices/SignUpSlice";
import { AppDispatch } from "../../../redux/User/types";
import { userResendVerify, userVerify } from "../../../redux/User/operations";
import { getCurrentEmail, getSignUpData } from "../../../redux/User/selectors";
import Button from "../../Common/Button/Button";
import Timer from "../../Common/Timer/Timer";
import ServerError from "../../Common/ServerError/ServerError";
import Loading from "../../Common/Loading/Loading";
import { timerActiveBtn } from "../../../utils/timerActiveBtn";
import { ModalOpenEndSingUp } from "./Modal.types";
import style from "./Modal.module.css";

const EndSingUp: React.FC<ModalOpenEndSingUp> = ({
  endSingUpHundler,
  getNewVerificationCode,
  successModalOpen,
  isChangeModalKey,
}): JSX.Element => {
  const [key, setKey] = useState("");
  const [keyError, setKeyError] = useState(false);
  const [emptyKeyError, setEmptyKeyError] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [userError, setUserError] = useState<null | number>(null);
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { timer } = useSelector(getSignUpData);
  const currentSeconds = Math.floor(new Date().getTime() / 1000);
  const time = timer ? 120 - (currentSeconds - timer) : 120;

  const email = useSelector(getCurrentEmail);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    timerActiveBtn(time, setIsDisabledBtn);
  }, [time]);

  const validateKey = (value: string) => {
    if (key === "" && value === "") {
      setEmptyKeyError(true);
      return;
    } else {
      setEmptyKeyError(false);
    }

    if (value.length === 4) {
      setKeyError(false);
    } else {
      setKeyError(true);
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (key === "") {
      setEmptyKeyError(true);
    }
    if (key && !keyError && !userError && isDisabledBtn) {
      handleServerResponse();
    }
  };

  const handleServerResponse = async () => {
    setIsLoading(true);

    try {
      const { payload } = await dispatch(userVerify({ otp: key }));
      if (typeof payload === "object" || payload === 200) {
        endSingUpHundler(false);
        successModalOpen(true);
        dispatch(addTimer(0));
      }
      if (payload === 404 || payload === 400) {
        setUserError(payload);
      }
      if (payload === 500) {
        setServerError(true);
      }
    } catch (error) {
      setServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getNewKey = async () => {
    dispatch(addTimer(0));
    setIsLoading(true);

    try {
      const { payload } = await dispatch(
        userResendVerify({ email: email as string })
      );
      if (typeof payload === "object") {
        const currentDate = new Date();
        const seconds = Math.floor(currentDate.getTime() / 1000);
        dispatch(addTimer(seconds));
        setIsDisabledBtn(true);
        getNewVerificationCode(true);
        endSingUpHundler(false);
        isChangeModalKey(false);
        return;
      }
      setServerError(true);
    } catch (error) {
      setServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.modal}>
        <div className={style.forgottenPassContentWrap}>
          <div className={style.completeModalInner}>
            <h2 className={`${style.title}`}>Реєстрація</h2>
            <div className={style.forggotenPassText}>
              <p>
                Вам на пошту відправлено код з підтвердженням реєстрації.
                Перевірте пошту та введіть код.
              </p>
              <Timer minutes={time} />
            </div>
            <form
              className={`${style.form} ${style.newPassForm}`}
              onSubmit={handleSubmitForm}
            >
              <ul className={`${style.inputList} ${style.newPasswordList}`}>
                <li className={style.inputItem}>
                  <label htmlFor="passwordKey" className={style.formLabel}>
                    Код підтвердження
                    <input
                      id="passwordKey"
                      autoComplete="on"
                      className={style.formInput}
                      type="number"
                      name="key"
                      placeholder="Введіть код"
                      onChange={(e) => {
                        setUserError(null);
                        setKey(e.target.value);
                        validateKey(e.target.value.trim());
                      }}
                      style={
                        emptyKeyError || keyError || userError || !isDisabledBtn
                          ? { borderColor: "#86181e" }
                          : key
                          ? { borderColor: "#009955" }
                          : { borderColor: "#93999b" }
                      }
                    />
                  </label>
                  {keyError && isDisabledBtn && (
                    <p className={style.errorMessage}>
                      Код має містити 4 цифри
                    </p>
                  )}
                  {userError === 404 && isDisabledBtn && (
                    <p className={style.errorMessage}>Код введено невірно</p>
                  )}
                  {(userError === 400 || !isDisabledBtn) && (
                    <p className={style.errorMessage}>Час дії коду вийшов</p>
                  )}
                  {serverError && <ServerError />}
                  {emptyKeyError && isDisabledBtn && (
                    <p className={style.errorMessage}>Це поле є обов'язковим</p>
                  )}
                  <button
                    type="button"
                    className={`${style.navigateBtn} ${style.newKey}`}
                    onClick={() => getNewKey()}
                    style={{ display: isDisabledBtn ? "none" : "block" }}
                  >
                    Отримати новий код
                  </button>
                </li>
              </ul>
              <Button title={"Зареєструватися"} modal />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EndSingUp;
