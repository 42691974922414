import React, { useEffect, useState } from "react";
import Loading from "../../../Common/Loading/Loading";
import icons from "../../../../assets/icons.svg";
import style from "./ServiceLogin.module.css";

interface ServiceProps {
  facebookError?: boolean;
}

const ServiceLogin: React.FC<ServiceProps> = ({facebookError}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAuth = (value: string) => {
    setIsLoading(true);
    window.location.href = `https://api.requiem.army/api/auth/${value}`;
  };

  useEffect(() => {
    setIsLoading(false);
    return () => setIsLoading(false);
  }, []);

  const AuthButton = ({ service }: { service: string }) => (
    <button className={style[service]} onClick={() => handleAuth(service)}>
      <svg>
        <use href={`${icons}#${service}`} />
      </svg>
      <span className={style.serviceTitle}>
        ПРОДОВЖИТИ З {service.toUpperCase()}
      </span>
    </button>
  );

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.wrap}>
        <p className={style.text}>або</p>
        <div className={style.wrapServises}>
          <AuthButton service="google" />
          {!facebookError && <AuthButton service="facebook" />}
        </div>
      </div>
    </>
  );
};

export default ServiceLogin;
