import React from "react";
import { createPortal } from "react-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingType } from "./Loading.types";
import styleLoading from "./Loading.module.css";
import style from "../../UI/ModalTemplate/Modal.module.css";

const overlay = document.querySelector("#modal-root");

const Loading: React.FC<LoadingType> = ({ saving }): JSX.Element => {
  return createPortal(
    <div className={style.overlay} style={{ zIndex: "10000" }}>
      {saving && (
        <div className={styleLoading.saving}>
          <p>Йде збереження сторінки.</p>
          <p>Це може тривати деякий час.</p>
        </div>
      )}
      <ClipLoader color="lightgrey" />
    </div>,
    overlay as Element
  );
};

export default Loading;
