import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTimer } from "../../../redux/User/slices/SignUpSlice";
import { checkUserEmail, getSignUpData } from "../../../redux/User/selectors";
import {
  AppDispatch,
  ForgotPasswordType,
  ResetPasswordType,
} from "../../../redux/User/types";
import { resetPassword, forgotPassword } from "../../../redux/User/operations";
import Timer from "../../Common/Timer/Timer";
import Loading from "../../Common/Loading/Loading";
import ServerError from "../../Common/ServerError/ServerError";
import Button from "../../Common/Button/Button";
import {
  includeNumberPattern,
  passwordPattern,
  upperCasePattern,
} from "../../../constants/patterns";
import { timerActiveBtn } from "../../../utils/timerActiveBtn";
import icons from "../../../assets/icons.svg";
import { ModalNewPassProps } from "./Modal.types";
import style from "./Modal.module.css";

const NewPasswordModal: React.FC<ModalNewPassProps> = ({
  changedPassModalHandler,
  newPassModalHandler,
  getNewVerificationCode,
  isChangeModalKey,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [key, setKey] = useState("");
  const [keyError, setKeyError] = useState(false);
  const [confirmKeyError, setConfirmKeyError] = useState(false);
  const [timeKeyError, setTimeKeyError] = useState(false);
  const [emptyKeyError, setEmptyKeyError] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [passVisability, setPassVisability] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [emptyPassError, setEmptyPassError] = useState(false);
  const [passLatinError, setPassLatinError] = useState(false);
  const [passIncludeNumberError, setPassIncludeNumberError] = useState(false);
  const [passIncCapLettersError, setPassIncCapLettersError] = useState(false);
  const [repeatPassVisability, setrepeatPassVisability] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPassError, setRepeatPassError] = useState(false);
  const [emptyRepeatPassError, setEmptyRepeatPassError] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const email = useSelector(checkUserEmail);

  const { timer } = useSelector(getSignUpData);
  const currentSeconds = Math.floor(new Date().getTime() / 1000);
  const time = timer ? 120 - (currentSeconds - timer) : 120;

  const validateKey = (value: string) => {
    if (key === "" && value === "") {
      setEmptyKeyError(true);
      return;
    } else {
      setEmptyKeyError(false);
    }

    if (value.length !== 4 && value) {
      setKeyError(true);
    } else {
      setKeyError(false);
    }
    setConfirmKeyError(false);
  };

  // password validate

  const validatePassword = (value: string) => {
    setEmptyPassError(false);
    if (value !== repeatPassword && repeatPassword) {
      setPasswordError(true);
      setRepeatPassError(true);
    } else {
      setRepeatPassError(false);
    }
    if (value.length < 8 || value.length > 20) {
      setPasswordError(true);
    }
    const isValidPass = passwordPattern.test(value);
    if (!isValidPass) {
      setPassLatinError(true);
      setPasswordError(true);
    } else {
      setPassLatinError(false);
    }
    const isIncludeNumber = includeNumberPattern.test(value);
    if (!isIncludeNumber) {
      setPassIncludeNumberError(true);
      setPasswordError(true);
    } else {
      setPassIncludeNumberError(false);
    }
    const inCapitalLetters = upperCasePattern.test(value);
    if (!inCapitalLetters) {
      setPassIncCapLettersError(true);
      setPasswordError(true);
    } else {
      setPassIncCapLettersError(false);
    }
    if (value === "") {
      setPasswordError(false);
    }
    if (
      value.length >= 8 &&
      isValidPass &&
      isIncludeNumber &&
      inCapitalLetters
    ) {
      setPasswordError(false);
    }
  };

  const validateRepeatPass = (value: string) => {
    if (password !== value) {
      setRepeatPassError(true);
    } else {
      setRepeatPassError(false);
    }
    if (value === "") {
      setRepeatPassError(false);
    }
    setEmptyRepeatPassError(false);
  };

  // reset password

  const onResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const data: ResetPasswordType = {
      otp: formData.get("otp") as string,
      password: formData.get("newPassword") as string,
      confirmPassword: formData.get("confirmPassword") as string,
      email: email,
    };

    if (key === "") {
      setEmptyKeyError(true);
    }
    if (password === "") {
      setEmptyPassError(true);
    }
    if (repeatPassword === "") {
      setEmptyRepeatPassError(true);
    }
    if (
      key &&
      password &&
      repeatPassword &&
      !keyError &&
      !passwordError &&
      !repeatPassError &&
      isDisabledBtn
    ) {
      submitForm(data);
    }
  };

  // submit

  const submitForm = async (data: ResetPasswordType) => {
    setIsLoading(true);
    setServerError(false);
    try {
      const { payload } = await dispatch(resetPassword(data));
      if (payload === 400) {
        setConfirmKeyError(true);
        return;
      }
      if (payload === 404) {
        setServerError(true);
        return;
      }
      changedPassModalHandler(true);
      newPassModalHandler(false);
    } catch (error) {
      setServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getNewKey = async (data: ForgotPasswordType) => {
    setIsLoading(true);
    setServerError(false);

    try {
      const { payload } = await dispatch(forgotPassword(data));
      if (payload === 404) {
        setServerError(true);
        return;
      }
      setIsDisabledBtn(true);
      getNewVerificationCode(true);
      newPassModalHandler(false);
      isChangeModalKey(true);
      const currentDate = new Date();
      const seconds = Math.floor(currentDate.getTime() / 1000);
      dispatch(addTimer(seconds));
    } catch (error) {
      setServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    timerActiveBtn(time, setIsDisabledBtn);

    if (!isDisabledBtn) {
      setTimeKeyError(true);
    } else {
      setTimeKeyError(false);
    }
  }, [isDisabledBtn, time]);

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.modal}>
        <div className={style.forgottenPassContentWrap}>
          <h2 className={`${style.title}`}>Забули пароль?</h2>
          <div className={style.forggotenPassText}>
            <p>
              Введіть код підтвердження, який ми надіслали вам на електронну
              пошту і введіть новий пароль.
            </p>
            <Timer minutes={time} />
          </div>
          <form
            className={`${style.form} ${style.newPassForm}`}
            onSubmit={onResetPassword}
          >
            <ul className={`${style.inputList} ${style.newPasswordList}`}>
              {/* password key */}

              <li className={style.inputItem}>
                <label htmlFor="passwordKey" className={style.formLabel}>
                  Код підтвердження
                  <input
                    id="otp"
                    autoComplete="on"
                    className={style.formInput}
                    type="number"
                    name="otp"
                    placeholder="Введіть код"
                    onChange={(e) => {
                      setKey(e.target.value);
                      validateKey(e.target.value.trim());
                    }}
                    style={
                      emptyKeyError ||
                      keyError ||
                      confirmKeyError ||
                      timeKeyError
                        ? { borderColor: "#681318", color: "#681318" }
                        : key !== "" && !keyError && !timeKeyError
                        ? { borderColor: "#009955" }
                        : undefined
                    }
                  />
                </label>
                {keyError && !timeKeyError && (
                  <p className={style.errorMessage}>Код має містити 4 цифри</p>
                )}
                {timeKeyError && (
                  <p className={style.errorMessage}>Час дії коду вийшов</p>
                )}
                {emptyKeyError && !timeKeyError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}
                {confirmKeyError && !timeKeyError && (
                  <p className={style.errorMessage}>Код введено невірно</p>
                )}
                <button
                  type="button"
                  style={{ display: isDisabledBtn ? "none" : "block" }}
                  className={`${style.navigateBtn} ${style.newKey}`}
                  onClick={() => getNewKey({ email: email })}
                >
                  Отримати новий код
                </button>
              </li>

              {/* new password */}

              <li className={style.inputItem}>
                <label htmlFor="newPassword" className={style.formLabel}>
                  Новий пароль
                  <input
                    id="newPassword"
                    autoComplete="on"
                    className={style.formInput}
                    type={passVisability ? "text" : "password"}
                    name="newPassword"
                    placeholder="Пароль має містити від 8 до 20 символів"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value.trim());
                      validatePassword(e.target.value.trim());
                    }}
                    style={
                      emptyPassError || passwordError
                        ? { borderColor: "#681318", color: "#681318" }
                        : password !== "" &&
                          !passwordError &&
                          !passLatinError &&
                          !passIncludeNumberError &&
                          !passIncCapLettersError
                        ? { borderColor: "#009955" }
                        : undefined
                    }
                  />
                </label>
                <div className={`${style.icon} ${style.backIcon} `}>
                  {!passVisability ? (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() => setPassVisability(!passVisability)}
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_close`} />
                    </svg>
                  ) : (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() => setPassVisability(!passVisability)}
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_open`} />
                    </svg>
                  )}
                </div>
                {emptyPassError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}
              </li>

              {/* repeat password */}

              <li className={style.inputItem}>
                <label htmlFor="repeatPassword" className={style.formLabel}>
                  Повторити пароль
                  <input
                    id="confirmPassword"
                    disabled={
                      passwordError || password.length === 0 ? true : false
                    }
                    autoComplete="on"
                    className={style.formInput}
                    type={repeatPassVisability ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Пароль має містити від 8 до 20 символів"
                    value={repeatPassword}
                    onChange={(e) => {
                      setRepeatPassword(e.target.value.trim());
                      validateRepeatPass(e.target.value.trim());
                    }}
                    style={
                      passwordError
                        ? { borderColor: "#93999b", color: "#93999b" }
                        : repeatPassError
                        ? { borderColor: "#86181e", color: "#86181e" }
                        : repeatPassword !== "" && !repeatPassError
                        ? { borderColor: "#009955" }
                        : { borderColor: "#93999b", color: "#000" }
                    }
                  />
                </label>

                <div className={`${style.icon} ${style.backIcon} `}>
                  {!repeatPassVisability ? (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() =>
                        setrepeatPassVisability(!repeatPassVisability)
                      }
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_close`} />
                    </svg>
                  ) : (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() =>
                        setrepeatPassVisability(!repeatPassVisability)
                      }
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_open`} />
                    </svg>
                  )}
                </div>
                {emptyRepeatPassError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}

                {/* errors list */}

                {password && (
                  <ul className={style.errorsList}>
                    <li
                      className={
                        !repeatPassError && repeatPassword.length !== 0
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Підтвердіть пароль</span>
                    </li>
                    <li
                      className={
                        !passIncludeNumberError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Пароль має містити мінімум одну цифру</span>
                    </li>
                    <li
                      className={
                        !passIncCapLettersError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Пароль має містити мінімум одну велику літеру</span>
                    </li>
                    <li
                      className={
                        password.length < 8 || password.length > 20
                          ? `${style.errorItem} ${style.error}`
                          : `${style.errorItem}`
                      }
                    >
                      <span>Пароль має містити від 8 до 20 символів</span>
                    </li>
                    <li
                      className={
                        !passLatinError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Пароль має бути латиницею (a-z)</span>
                    </li>
                  </ul>
                )}
              </li>
            </ul>

            <Button modal title={"Змінити пароль"} />
            {serverError && <ServerError />}
          </form>
        </div>
      </div>
    </>
  );
};
export default NewPasswordModal;
