import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addTokenFromServices } from "../../../redux/User/slices/UserSlice";
import { AppDispatch } from "../../../redux/User/types";

const ServicePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenValue = urlParams.get("token");
    const facebookEmailError = urlParams.get("email");

    if (tokenValue) {
      dispatch(addTokenFromServices(tokenValue));
      navigate("/user/profile");
      return;
    }
    if (facebookEmailError) {
      navigate("/", { state: { facebookError: true } });
      return;
    }
    navigate("/");
  }, [dispatch, navigate]);

  return null;
};

export default ServicePage;
