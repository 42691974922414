import React from "react";
import { ModalFileSizeErrorType } from "./ModalFileSizeError.types";
import style from "./ModalFileSizeError.module.css";

const ModalFileSizeError: React.FC<ModalFileSizeErrorType> = ({
  title,
  text,
}) => {
  return (
    <div className={style.modal}>
      <h1 className={style.title}>{title}</h1>
      <p className={style.text}> {text}</p>
    </div>
  );
};

export default ModalFileSizeError;
