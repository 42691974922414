import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { RootState } from "../../../redux/User/types";
import { useSelector } from "react-redux";
import { StepsType } from "../stepsInfo/StepsInfo.types";
import ModalsWrapper from "../../UI/Modals/ModalsWrapper";
import Button from "../../Common/Button/Button";
import icons from "../../../assets/icons.svg";
import style from "./StepInfoBlock.module.css";

const modalRoot = document.querySelector("#modal-root");

const StepInfoBlock: React.FC<StepsType> = ({
  number,
  title,
  shortText,
  longText,
  btn,
}): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openSignUpModalWrapper, setOpenSignUpModalWrapper] = useState(false);

  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        setIsOpenModal(false);
        document.body.classList.remove("lock");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenModal]);

  const onCloseModal = () => {
    setIsOpenModal(false);
    document.body.classList.remove("lock");
  };

  const onCloseModalOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget === event.target) {
      onCloseModal();
    }
  };

  const onOpenSingUpModal = () => {
    onCloseModal();
    setOpenSignUpModalWrapper(true);
    document.body.classList.add("lock");
  };

  const onOpenModal = () => {
    document.body.classList.add("lock");
    setIsOpenModal(true);
  };

  const modalInfo = createPortal(
    <div className={style.overlay} onClick={onCloseModalOverlay}>
      <div className={style.stepModal}>
        <button className={style.closeBtn} onClick={onCloseModal}>
          <svg className={style.closeImg} width={18} height={18}>
            <use href={`${icons}#close_step_modal`} />
          </svg>
        </button>
        <h2 className={style.modalTitle}>{title}</h2>
        <p className={style.longText}>{longText}</p>
        {btn && (
          <>
            {!isLoggedIn && (
              <div className={style.btnWrapper} onClick={onOpenSingUpModal}>
                <Button title={title} />
              </div>
            )}
          </>
        )}
      </div>
    </div>,
    modalRoot as Element
  );

  return (
    <>
      {isOpenModal && modalInfo}

      <div
        className={
          number === 1 && !isOpenModal
            ? `${style.blockWrap} ${style.firstBlock}`
            : style.blockWrap
        }
        onClick={number === 1 ? onOpenModal : undefined}
      >
        <div className={style.content}>
          <div className={style.titleWrap}>
            <span className={style.number}>{number}</span>
            <h4 className={style.title}>{title}</h4>
          </div>
          <p className={style.shortText}>{shortText}</p>
        </div>
      </div>
      {openSignUpModalWrapper && (
        <ModalsWrapper
          openModalWrapperLogic={setOpenSignUpModalWrapper}
          openSignUpModal
        />
      )}
    </>
  );
};

export default StepInfoBlock;
