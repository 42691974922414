import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserStatus } from "../../../redux/User/selectors";
import Button from "../../Common/Button/Button";
import ModalsWrapper from "../../UI/Modals/ModalsWrapper";
import style from "./Hero.module.css";

const Hero: React.FC = (): JSX.Element => {
  const [openSignUpModalWrapper, setOpenSignUpModalWrapper] = useState(false);
  const [openFacebookErrorModal, setOpenFacebookErrorModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector(getUserStatus);

  useEffect(() => {
    if (location?.state?.facebookError && !isLoggedIn) {
      setOpenFacebookErrorModal(true);
      document.body.classList.add("lock");
    }
  }, [location, isLoggedIn]);

  const openModal = () => {
    setOpenSignUpModalWrapper(true);
    document.body.classList.add("lock");
  };

  const handleSignUpModalOpening = async () => {
    setOpenFacebookErrorModal(false);
    setOpenSignUpModalWrapper(true);
  };

  return (
    <>
      <section className={style.wrapper}>
        <h1 className={style.title}>Requiem - пам’ятай героїв</h1>
        <h1 className={style.mobileTitleWrap}>
          <p className={style.mobileTitle}>Requiem</p>
          <p className={style.mobileSubtitle}>пам’ятай героїв</p>
        </h1>
        <p className={style.subtitle}>
          Cайт-меморіал пам&#39;яті військовослужбовців, які загинули під час
          широкомасштабного вторгнення росії в Україну.
        </p>
        {isLoggedIn ? (
          <div
            className={style.btnWrapper}
            onClick={() => navigate("/user/memory")}
          >
            <Button title="Створити сторінку пам&#39;яті" />
          </div>
        ) : (
          <div className={style.btnWrapper} onClick={openModal}>
            <Button title="Зареєструватись" />
          </div>
        )}
      </section>
      {openSignUpModalWrapper && (
        <ModalsWrapper
          openModalWrapperLogic={setOpenSignUpModalWrapper}
          openSignUpModal
        />
      )}
      {openFacebookErrorModal && (
        <ModalsWrapper
          openModalWrapperLogic={setOpenFacebookErrorModal}
          updateSuccess={{
            modalIsOpen: setOpenFacebookErrorModal,
            title: "Помилка реєстрації",
            subtitle:
              "У вашому акаунті відсутня електронна пошта. Оберіть інший спосіб реєстрації",
            btnTitle: "Зареєструватися",
            facebookLogic: true,
            logic: handleSignUpModalOpening,
          }}
        />
      )}
    </>
  );
};

export default Hero;
