import { PolicyProps } from "./types";
import style from "./Policy.module.css";

const Policy: React.FC<PolicyProps> = ({ modal }): JSX.Element => {
  const subtitleStyles = modal
    ? `${style.subtitle} ${style.subtitleModal}`
    : `${style.subtitle}`;
  const policyItemStyles = modal
    ? `${style.policyItem} ${style.policyItemModal}`
    : `${style.policyItem}`;

  return (
    <div className={style.content} style={{ padding: modal ? "0px" : "" }}>
      {!modal && (
        <h1 className={style.title}>Політика конфіденційності сайту.</h1>
      )}
      <ol className={style.policyList}>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Вступ.</h2>
          <ul className={style.policySubList}>
            <li>
              1.1. Ця політика конфіденційності діє для всіх інформаційних
              продуктів, розміщених на Інтернет-ресурсі «Requiem». Ця Політика
              пояснює, яким чином Ми отримуємо, зберігаємо, обробляємо,
              використовуємо, передаємо та захищаємо будь-які персональні дані,
              які Ми збираємо про наших Користувачів. В рамках використання
              цього Веб-сайту, ми повинні дотримуватися і положень Закону
              України «Про захист персональних даних», і положень General Data
              Protection Regulation (Regulation (EU) 2016/679).
            </li>
            <li>
              1.2. Ми залишаємо за собою право публікувати окремі примітки про
              конфіденційність для певних інформаційних продуктів та їхніх
              авторів, а також у будь-який час змінювати політику
              конфіденційності на свій розсуд.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Терміни.</h2>
          <ul className={style.policySubList}>
            <li>
              2.1. «Сайт» - означає цей офіційний Веб-сайт, на якому розміщена
              Політика.
            </li>
            <li>
              2.2. «Суб'єкти персональних даних» – це відвідувачі Веб-сайту
              та/або особи, які використовують функціональні можливості
              Веб-сайту (далі за текстом – «Користувачі» або «Ви»).
            </li>
            <li>
              2.3. «Використання персональних даних» – це збирання, зберігання,
              оброблення, захист, передача персональних даних в порядку, що
              встановлений чинним законодавством України, General Data
              Protection Regulation (Regulation (EU) 2016/679) та цією
              Політикою.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Загальні принципи.</h2>
          <ul className={style.policySubList}>
            <li>
              3.1. Ми дотримуємося принципів, встановлених General Data
              Protection Regulation (Regulation (EU) 2016/679), а саме –
              персональні дані:
              <ul className={style.policySubList}>
                <li>
                  3.1.1. Обробляються нами законно, чесно і прозоро щодо всіх
                  Користувачів;
                </li>
                <li>
                  3.1.2. Збираються для певних, явних і законних цілей та не
                  обробляються в подальшому таким чином, який несумісний з
                  цілями для яких такі данні збиралися («Принцип обмеження
                  мети»);
                </li>
                <li>
                  3.1.3. Є адекватними, відповідними і обмеженими з точки зору
                  цілей, для яких вони обробляються («Принцип мінімізації
                  даних»);
                </li>
                <li>
                  3.1.4. Точні, а також при необхідності – оновлені дані. Ми
                  здійснюємо розумні та своєчасні заходи для забезпечення того,
                  щоб персональні дані, у яких були виявлені неточності або
                  помилки, з урахуванням цілей, для яких вони були оброблені,
                  були стерті або виправлені без затримки («Принцип точності»);
                </li>
                <li>
                  3.1.5. Зберігаються в формі, яка дозволяє ідентифікувати
                  Користувачів не довше, ніж це необхідно для цілей, для яких
                  обробляються персональні дані («Принцип обмеженого
                  зберігання»);
                </li>
                <li>
                  3.1.6. Обробляються таким чином, який забезпечує належний
                  захист персональних даних, включаючи захист від
                  несанкціонованої або незаконної обробки, а також від
                  випадкової втрати, знищення, викрадення, незаконного
                  заволодіння, або пошкодження з використанням відповідних
                  технічних або організаційних заходів («Принцип цілісності і
                  конфіденційності»).
                </li>
                <li>
                  3.1.7. Обробка персональних даних здійснюється відкрито і
                  прозоро із застосуванням засобів та у спосіб, що відповідають
                  визначеним цілям такої обробки («Принцип прозорості»).
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Збір персональних даних.</h2>
          <ul className={style.policySubList}>
            <li>4.1. Ми збираємо та використовуємо такі персональні дані: </li>
            <li>
              4.2. Інформація про ваш комп’ютер, включаючи вашу IP-адресу,
              географічне розташування, тип і версію браузера та операційну
              систему;
            </li>
            <li>
              4.3. Інформація про ваші відвідування та використання цього
              Веб-сайту, включаючи реферальні джерела, протяжність візитів,
              переглянуті сторінки та шляхи навігації по сайту;
            </li>
            <li>
              4.4. Інформація про адресу вашої електронної пошти, яку ви
              використали для реєстрації на нашому Веб-сайті;
            </li>
            <li>
              4.5. Інформація, яку ви ввели під час створення профілю на нашому
              веб-сайті – наприклад, ваше ім’я, зображення у вашому профілі,
              стать, дата народження, сімейний статус, хобі та інтереси, освіта
              та місце роботи;
            </li>
            <li>
              4.6. Інформація, така, як ваше ім’я та адреса електронної пошти,
              які ви вказали під час оформлення підписок на наші поштові
              повідомлення та/або розсилки;
            </li>
            <li>
              4.7. Інформація, яку ви ввели під час використання сервісів нашого
              Веб-сайту;
            </li>
            <li>
              4.8. Інформація, яка генерується при використанні нашого
              Веб-сайту, включаючи інформацію про час, частоту та умови його
              використання;
            </li>
            <li>
              4.9. Інформація, яку ви розмістили на нашому Веб-сайті з метою
              публікації її в Інтернеті, включаючи ваше ім’я користувача,
              зображення профілю та зміст вашої публікації;
            </li>
            <li>
              4.10. Інформація, що міститься у будь-яких повідомленнях, які ви
              надсилали нам електронною поштою або через наш веб-сайт, включаючи
              зміст повідомлення та мета дані;
            </li>
            <li>
              4.11. Будь-яка інша персональна інформація, яку ви надіслали нам.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Доведення до відома користувачів про збирання персональних даних.
          </h2>
          <ul className={style.policySubList}>
            <li>
              5.1. Ми здійснюємо виключно законне та правомірне збирання
              персональних даних. Ми цією Політикою інформуємо Користувачів про
              те, що персональні дані Користувачів збираються, використовуються,
              обговорюються, оброблюються, захищаються, передаються або іншим
              чином опрацьовуються (Використовується) виключно з одночасним
              дотриманням принципів, які встановлені цією Політикою.
            </li>
            <li>
              5.2. Ознайомлення з положеннями цієї Політики Користувачем
              означає, що Користувач підтверджує та гарантує свою обізнаність
              про ризики, правила, гарантії та права щодо Використання
              персональних даних і про те, як реалізувати свої права у зв’язку з
              таким Використанням.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Використання вашої персональної інформації.
          </h2>
          <ul className={style.policySubList}>
            <li>6.1. Ми використовуємо Ваші персональні дані для:</li>
            <li>6.2. Адміністрування нашого Веб-сайту;</li>
            <li>6.3. Персоналізація нашого Веб-сайту для вас;</li>
            <li>
              6.4. Надання Вам можливості користуватися сервісами, доступними на
              нашому Веб-сайті;
            </li>
            <li>
              6.5. Надсилання вам електронною поштою повідомлень, які ви
              спеціально запросили;
            </li>
            <li>
              6.6. Надсилання вам електронної розсилки, якщо ви її замовили (ви
              в будь-який момент можете повідомити нас, що більше не бажаєте
              отримувати електронні розсилки від нас);
            </li>
            <li>
              6.7. Надання стороннім компаніям статистичної інформації про наших
              користувачів (проте, ці сторонні компанії не матимуть змоги
              ідентифікувати жодного окремого користувача з цих даних);
            </li>
            <li>
              6.8. Обробка запитів та скарг, зроблених вами або на вас, і які
              стосуються нашого Веб-сайту;
            </li>
            <li>
              6.9. З метою забезпечення безпеки нашого Веб-сайту та попередження
              шахрайства;
            </li>
            <li>
              6.10. З метою перевірки відповідності умовам та правилам, які
              регламентують використання нашого Веб-сайту (включаючи моніторинг
              приватних повідомлень, надісланих через сервіс приватних
              повідомлень нашого веб-сайту), та в інших цілях.
            </li>
            <li>
              6.11. Якщо Ви надали персональну інформацію для публікації на
              нашому веб-сайті, ми публікуємо її.{" "}
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Розкриття ваших персональних даних.
          </h2>
          <ul className={style.policySubList}>
            <li>
              7.1. Ваші персональні дані для цілей, описаних вище, можуть
              розкриватися (передаватися) нами будь-якій нашій афілійованій
              компанії або бізнес партнерам та/або контрагентам (незалежно від
              їх територіального розташування). Ми гарантуємо, що такі компанії
              обізнані про правильність обробки персональних даних згідно
              General Data Protection Regulation (Regulation (EU) 2016/679) і
              дотримуються положень даного нормативного акту та цієї Політики.
            </li>
            <li>
              7.2. Перед тим, як ми розкриваємо (передаємо) певній афілійованій
              компанії або бізнес партнеру/контрагенту персональні дані про
              Користувача, такі компанії будуть проінформовані про
              обов’язковість дотримання положень цієї Політики.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Збереження та захист персональних даних.
          </h2>
          <ul className={style.policySubList}>
            <li>
              8.1. При Використанні персональних даних ми забезпечуємо мережеву
              та інформаційну безпеку, тобто здатності мережі чи інформаційної
              системи чинити опір, на відповідному рівні довіри, випадковим
              подіям або незаконним чи зловмисним діям, що ставлять під загрозу
              наявність, автентичність, цілісність та конфіденційність
              збережених або переданих персональних даних, і безпеки пов’язаних
              послуг, які пропонують через такі мережі чи системи.
            </li>
            <li>
              8.2. Ми застосовуємо виключно надійні та сучасні сервіси та
              програми, які дозволяють забезпечити збереження та захист
              персональних даних Користувачів на найвищому рівні. При
              Використанні персональних даних, ми здійснюємо комплекс заходів,
              які унеможливлюють несанкціонований
              доступ/крадіжку/виток/незаконне оприлюднення та/або незаконне
              Використання персональних даних. Сучасні сервіси та програми
              забезпечують:
            </li>
            <li>
              <ul className={style.policySubList}>
                <li>
                  8.2.1. Використання псевдонімів і шифрування персональних
                  даних;
                </li>
                <li>
                  8.2.2. Здатність забезпечувати безперервну конфіденційність,
                  цілісність, наявність та стійкість систем та послуг
                  опрацювання;
                </li>
                <li>
                  8.2.3. Здатність вчасно відновити наявність і доступ до
                  персональних даних у випадку технічної аварії;
                </li>
                <li>
                  8.2.4. Процес для регулярного тестування, оцінювання та
                  аналізу результатів.
                </li>
                <li>
                  8.3. Ми самостійно перевіряємо, чи були реалізовані всі
                  належні заходи технологічного захисту та організаційні заходи
                  для того, щоб негайно встановити, чи відбулося порушення
                  захисту персональних даних, а також чи були належним чином
                  повідомлені правоохоронні органи і Користувачі.
                </li>
                <li>
                  8.4. Ми самостійно аналізуємо ризики та негативні наслідки
                  порушення захисту персональних даних Користувачів та надаємо
                  Користувачам відповідні рекомендації у випадку необхідності.
                  Ми проводимо оцінювання впливу на захист даних до моменту
                  Використання для того, щоб визначити конкретну ймовірність і
                  ступінь тяжкості високого ризику порушення захисту
                  персональних даних, враховуючи специфіку, обсяг, контекст і
                  цілі опрацювання та джерела ризику.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Права користувача.</h2>
          <ul className={style.policySubList}>
            <li>
              9.1. Користувач має право:
              <ul className={style.policySubList}>
                <li>
                  9.1.1. Вимагати від нас виправлення, блокування, стирання
                  та/або видалення персональних даних або заперечувати проти
                  такої обробки шляхом оформлення відповідного запиту на адресу,
                  яка вказана на Веб-сайті.
                </li>
                <li>
                  9.1.2. Надавати нам неповні персональні дані (за умови надання
                  додаткової підписаної заяви в довільній формі з поясненням
                  причин);
                </li>
                <li>
                  9.1.3. Знати про джерела збирання, місця та засоби зберігання
                  своїх персональних даних, мету їх обробки.
                </li>
                <li>
                  9.1.4. Надсилати запит до Нас, запитувати і отримувати
                  персональні дані про Вас, які були надані Вами в
                  структурованому форматі, що зрозуміло читається шляхом
                  оформлення відповідного запиту і передавати ці дані іншому
                  контролеру без перешкод.{" "}
                </li>
                <li>
                  9.1.5. Запросити доступ до персональних даних, які Ми
                  зберігаємо відносно Вас, шляхом оформлення відповідного запиту
                  на адресу, яка вказана на Веб-сайті.
                </li>
                <li>
                  9.1.6. Запросити передбачуваний період, протягом якого Ваші
                  персональні дані будуть зберігатися Нами, а в разі, якщо це
                  неможливо, критерії, згідно з якими визначається строк /
                  термін зберігання такої інформації або на період яких умов
                  Вані персональні данні зберігаються, шляхом оформлення
                  відповідного запиту на адресу, яка вказана на Веб-сайті.
                </li>
                <li>
                  9.1.7. Отримувати не пізніш як за тридцять календарних днів з
                  дня надходження запиту, крім випадків, передбачених
                  законодавством України, відповідь про те, чи обробляються Ваші
                  персональні дані, а також отримувати зміст таких персональних
                  даних. При певних обставинах Ваш запит може оброблятися за
                  додаткову незначну плату для покриття адміністративних витрат,
                  пов'язаних з ним;
                </li>
                <li>
                  9.1.8. Запросити у Нас точну мету (цілі) обробки Ваших
                  персональних даних, які обробляються, шляхом оформлення
                  відповідного запиту.
                </li>
                <li>
                  9.1.9. Отримувати інформацію про умови надання доступу до
                  своїх персональних даних, зокрема інформацію про третіх осіб,
                  яким передаються (передавалися) Ваші персональні дані.
                </li>
                <li>
                  9.1.10. Пред’являти вмотивовану вимогу на Нашу адресу із
                  запереченням проти обробки своїх персональних даних.
                </li>
                <li>
                  9.1.11. Пред'являти вмотивовану вимогу щодо зміни або знищення
                  своїх персональних даних, якщо ці дані обробляються незаконно
                  чи є недостовірними.
                </li>
                <li>
                  9.1.12. На захист своїх персональних даних від незаконної
                  обробки та випадкової втрати, знищення, пошкодження,
                  ненаданням чи несвоєчасним їх наданням, а також на захист від
                  надання відомостей, що є недостовірними чи ганьблять Вашу
                  честь, гідність та ділову репутацію;
                </li>
                <li>
                  9.1.13. Звертатися із скаргами на обробку своїх персональних
                  даних до Уповноваженого Верховною Радою України з прав людини
                  або до суду;
                </li>
                <li>
                  9.1.14. Застосовувати засоби правового захисту в разі
                  порушення законодавства про захист персональних даних;
                </li>
                <li>
                  9.1.15. Вносити застереження стосовно обмеження права на
                  обробку своїх персональних даних під час надання згоди,
                  зокрема, якщо має місце одна з наступних умов:точність
                  персональних даних заперечується Вами протягом періоду, що
                  дозволяє Нам перевірити точність персональних даних; обробка є
                  незаконною, і Ви виступаєте проти стирання персональних даних,
                  і замість цього вимагаєте обмеження їх використання; Нам
                  більше не потрібні персональні дані для обробки, але вони
                  потрібні для встановлення, реалізації або захисту юридичних
                  вимог; Ви заперечували проти обробки своїх персональних даних
                  до перевірки законних підстав для обробки таких даних.
                </li>
                <li>
                  9.1.16. Відкликати згоду на обробку персональних даних шляхом
                  оформлення відповідного запиту.
                </li>
                <li>
                  9.1.17. Знати механізм автоматичної обробки персональних
                  даних;
                </li>
                <li>
                  9.1.18. На захист від автоматизованого рішення, яке має для
                  Вас правові наслідки;
                </li>
                <li>
                  9.1.19. Відмовитися від отримання повідомлень про наші
                  спеціальні пропозиції і послуги і будь-яких розсилок шляхом
                  оформлення відповідного запиту.
                </li>
                <li>
                  9.1.20. Здійснити запит на повне видалення (знищення)
                  персональних даних про себе, направивши відповідний запит. Ми
                  здійснюємо видалення (знищення) персональних даних у випадку
                  якщо немає більше потреби в персональних даних для цілей, для
                  яких їх збирали та/або якщо відсутня підстава для законного
                  Використання персональних даних та/або якщо персональні дані
                  необхідно стерти для дотримання встановленого законом
                  зобов’язання, закріпленого в законодавстві України.
                </li>
              </ul>
            </li>
            <li>
              9.2. Користувач має інші права щодо персональних даних, які
              передбаченні чинним законодавством України.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Обов'язки користувача.</h2>
          <ul className={style.policySubList}>
            <li>
              10.1. Надавати свої точні і правдиві персональні дані в повному
              обсязі.
            </li>
            <li>
              10.2. Негайно надавати Нам свої оновлені персональні дані в разі
              їх зміни.
            </li>
            <li>
              10.3. Негайно повідомляти про факт несанкціонованого отримання
              третьою особою Ваших персональних даних, якщо Вам стало відомо про
              такий факт;
            </li>
            <li>
              10.4. Повідомити Нас в разі виникнення незгоди з будь-якою з цілей
              обробки даних або бажання припинити обробку своїх персональних
              даних Нами шляхом оформлення відповідного запиту.
            </li>
            <li>
              10.5. Користувач повністю усвідомлює, що відправка повідомлення
              про незгоду з будь-якою з цілей обробки персональних даних Нами
              та/або про намір припинити обробку своїх персональних даних, є
              підставою для припинення взаємовідносин Сторін.
            </li>
            <li>
              10.6. Ви самостійно несете відповідальність за правдивість,
              точність і актуальність своїх персональних даних, наданих Нам.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Наші обов'язки.</h2>
          <ul className={style.policySubList}>
            <li>
              11.1. Повідомляти про будь-яке виправлення або стирання
              персональних даних, або обмеження обробки персональних даних
              користувача кожній третій особі, що раніше отримала персональні
              дані користувача від Нас з метою реалізації цілей обробки даних,
              встановлених цією Політикою, якщо це не є неможливим і не вимагає
              застосування Нами неспівмірних зусиль;
            </li>
            <li>
              11.2. Інформувати Користувача про одержувачів його персональних
              даних (третіх осіб), якщо від такого користувача надійшов
              відповідний запит;
            </li>
            <li>
              11.3. На вимогу Користувача шляхом оформлення відповідного запиту
              надавати йому в структурованому, що зазвичай використовуються, і
              «машинозчитуваному» форматі його персональні дані, які
              зберігаються Нами;
            </li>
            <li>
              11.4. В разі факту витоку персональних даних Користувача
              повідомити Відповідального працівника протягом 72 годин з моменту
              отримання інформації про наявність такого факту. Якщо повідомлення
              Відповідального працівника протягом 72 годин є неможливим, Ми
              зобов'язані повідомити Відповідального працівника про причини
              затримки в повідомленні;
            </li>
            <li>
              11.5. Негайно повідомити Користувача про витік його персональних
              даних у разі, якщо такий витік може створити загрозу правам і
              свободам такого Користувача; Сторони також мають всі права і
              обов'язки, передбачені законодавством про захист персональних
              даних, що регулюють правовідносини між Нами та Користувачем;
            </li>
            <li>
              11.6. Дозволяти використання персональних даних Користувачів
              нашими працівниками та третіми особами лише відповідно до їхніх
              професійних, службових або трудових обов'язків. Такі працівники/
              треті особи зобов'язані не допускати розголошення у будь-який
              спосіб персональних даних, які їм було довірено або які стали
              відомі їм у зв'язку з виконанням професійних, службових або
              трудових обов'язків, крім випадків, передбачених законом. Таке
              зобов'язання чинне після припинення ними діяльності, пов'язаної з
              персональними даними, крім випадків, установлених законом;
            </li>
            <li>
              11.7. Вносити зміни до персональних даних Користувача на підставі
              його вмотивованої письмової вимоги;
            </li>
            <li>
              11.8. Вносити зміни до персональних даних також за зверненням
              інших суб’єктів відносин, пов’язаних із персональними даними
              Користувача, якщо на це є згода відповідного Користувача або
              відповідна зміна здійснюється згідно з приписом уповноваженого
              органу державної влади чи за рішенням суду, що набрало законної
              сили;
            </li>
            <li>
              11.9. Невідкладно внести зміни до персональних даних, які не
              відповідають дійсності, з моменту встановлення невідповідності.
            </li>
            <li>
              11.10. Забезпечити вжиття необхідних та результативних заходів і
              бути спроможним довести відповідність діяльності з Використання
              даних відповідно до General Data Protection Regulation (Regulation
              (EU) 2016/679), в тому числі дієвість заходів. Такі заходи повинні
              враховувати специфіку, масштаби, контекст і цілі опрацювання та
              ризик для прав і свобод Користувачів.
            </li>
            <li>
              11.11. Своєчасно та швидко повідомляти Користувачів про порушення
              захисту персональних даних, без неналежної затримки, якщо таке
              порушення захисту персональних даних ймовірно створить високий
              ризик для прав і свобод Користувачів для того, щоб Користувачі
              могли вжити необхідних запобіжних заходів. У відповідному
              повідомленні Ми описуємо специфіку порушення захисту персональних
              даних, а також надаємо рекомендації для Користувачів з метою
              зменшення потенційних негативних наслідків. Ми надаємо такі
              повідомлення Користувачам якомога швидше та в тісній співпраці з
              відповідними правоохоронними органами державної влади за потреби
              (наприклад Національною поліцією, Кіберполіцією тощо),
              дотримуючись настанов, наданих такими органами. Ми маємо право
              самостійно звертатися до відповідних правоохоронних органів у
              випадку порушення захисту персональних даних Користувачів.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Сторонні веб-сайти.</h2>
          <ul className={style.policySubList}>
            <li>
              12.1. Наш Веб-сайт містить гіперпосилання на, та деталі про
              веб-сайти сторонніх компаній та осіб. Ми не маємо інструментів
              керування, та не несемо відповідальності за політику
              конфіденційності й практики сторонніх осіб та компаній в цій
              галузі.
            </li>
            <li>
              12.2. Будь-ласка, своєчасно повідомляйте нас, якщо ваша
              персональна інформація, яка знаходиться у нас, потребує оновлення
              чи виправлень.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Правила користування інтерактивним ресурсом "Requiem".
          </h2>
          <ul className={style.policySubList}>
            <li>
              13.1. Адміністрація сайту визначає правила поведінки на ньому і
              залишає за собою право вимагати їх виконання, а також змінювати
              дані правила в будь-який час, публікуючи зміни на сайті. Незнання
              правил не звільняє від необхідності їх виконання.
            </li>
            <li>
              13.2. Всі права на будь-які матеріали, опубліковані на сайті,
              захищені відповідно до українського та міжнародного законодавства
              про авторське право і суміжні права.
            </li>
            <li>
              13.3. Адміністрація сайту не несе відповідальності за зміст
              матеріалів та інформаційних статей, коментарів користувачів, які
              розміщені на сторінках сайту, а також за наслідки їхньої
              публікації та/або використання. Відповідальність за повідомлення
              та інший контент на сайті несе користувач, який їх розмістив.
            </li>
            <li>
              13.4. Думки авторів статей, коментарів, розміщених на сторінках
              сайту «Requiem», можуть не збігатися з думками і позицією
              адміністраторів сайту.
            </li>
            <li>
              13.5. Адміністрація сайту не несе жодної відповідальності за
              збитки, понесені в результаті використання та/або невикористання
              інформації та сервісів «Requiem».
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Доступ, виправлення, стирання і видалення даних.
          </h2>
          <ul className={style.policySubList}>
            <li>
              14.1. Якщо Ви бажаєте переглянути будь-яку персональну інформацію,
              яку ми зберігаємо стосовно Вас або якщо Ви хочете внести зміни у
              Ваші персональні дані або видалити їх, якщо Ви хочете отримати
              інформацію про те, як Ваші персональні дані використовуються, як
              ми забезпечуємо конфіденційність Ваших персональних даних, Ви
              можете подати нам відповідний запит.
            </li>
            <li>
              14.2. З метою дотримання конфіденційності, при подачі запиту Вам
              також необхідно подати підтвердження, що ідентифікує Вашу особу.
              Для даної мети Вам необхідно додати до запиту копію Вашого
              паспорта.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Застереження.</h2>
          <ul className={style.policySubList}>
            <li>
              15.1. Читачем сайту може стати кожен, хто зайде за адресою
              Веб-сайту. Користувачі мають можливість додавати свої коментарі до
              публікацій, дозвіл на публікацію коментарів надають лише автори
              публікації.
            </li>
            <li>
              15.2. Будь-яка інформація, яку користувач надає, вказавши своє
              справжнє ім’я, зображення чи фотографію, стає загальнодоступною.
              Адміністрація сайту не несе відповідальність за особисту та іншу
              інформацію, яку користувачі оприлюднюють у коментарях, і не
              перевіряє достовірність даної інформації, окрім випадків, коли у
              цьому є обґрунтована потреба. Автором спогаду на сайті може стати
              кожен, хто звернеться з відповідною пропозицією до адміністрації
              сайту, надасть підтверджуючу інформацію про спорідненість з
              загиблим.
            </li>
            <li>
              15.3. Якщо користувач (читач) вважає, що на сайті «Requiem»
              присутня інформація, що порушує його права, він може повідомити
              про це адміністрацію сайту, шляхом надсилання відповідного
              повідомлення на електронну адресу і надати аргументи, які
              підтверджують порушення його прав.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Відповідальний працівник.</h2>
          <ul className={style.policySubList}>
            <li>
              16.1. Ми забезпечуємо наявність особи, яка відповідальна за
              Використання персональних даних, дотримання норм цієї Політики,
              законодавства України та вимог General Data Protection Regulation
              (Regulation (EU) 2016/679).
            </li>
            <li>
              16.2. Відповідальний працівник з питань захисту даних
              призначається на підставі професійних якостей і, зокрема,
              експертних знань із права та практики захисту даних, а також
              здатності виконувати завдань, які спрямовані на дотримання норм
              цієї Політики, законодавства України та вимог General Data
              Protection Regulation (Regulation (EU) 2016/679).
            </li>
            <li>
              16.3. Відповідальний працівник з питань захисту даних має
              щонайменше такі завдання:
              <ul className={style.policySubList}>
                <li>
                  16.3.1. Інформувати та надавати рекомендації Нам і нашим
                  працівникам, які здійснюють Використання персональних даних,
                  щодо їхніх обов’язків відповідно до цієї Політики;
                </li>
                <li>
                  16.3.2. Здійснювати моніторинг відповідності цієї Політики
                  положенням про захист даних, які передбачені законодавством
                  України та General Data Protection Regulation (Regulation (EU)
                  2016/679).
                </li>
                <li>
                  16.3.3. Забезпечує розподіл обов’язків, підвищення обізнаності
                  та підготовку персоналу, залученого до Використання
                  персональних даних, та забезпечує здійснення відповідних
                  перевірок персоналу;
                </li>
                <li>
                  16.3.4. На запит, надавати рекомендації щодо оцінювання впливу
                  на захист даних і здійснювати моніторинг його проведення
                  відповідно до цієї Політики;
                </li>
                <li>
                  16.3.5. Співпрацювати із правоохоронними органами у випадку
                  порушення цієї Політики та законодавства України;
                </li>
                <li>
                  16.3.6. Діяти в якості координаційної особи для Нам з питань,
                  що стосуються Використання персональних даних, в тому числі,
                  надавати попередні консультації.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>На веб-сайті заборонено.</h2>
          <ul className={style.policySubList}>
            <li>
              17.1. Заклики до насильницької зміни чи повалення конституційного
              ладу або до захоплення державної влади; заклики до погромів,
              підпалів, знищення майна, захоплення будівель або споруд, які є у
              власності громади; заклики до агресії проти народу України та
              нацменшин, які тут проживають і підтримують єдність і суверенітет
              України; заклики до тероризму та сепаратизму;
            </li>
            <li>
              17.2. Прямі та непрямі образи інших читачів, користувачів,
              адміністрації та авторів сайту щодо їхньої національної, етнічної,
              расової чи релігійної приналежності, образи честі, гідності та
              ділової репутації, а також шовіністичні висловлювання;
            </li>
            <li>
              17.3. Нецензурні вислови та висловлювання порнографічного
              характеру;
            </li>
            <li>
              17.4. Висловлювання, метою яких є навмисне провокування агресивної
              реакції адміністрації та інших користувачів ресурсу;
            </li>
            <li>
              17.5. Дії, спрямовані на введення адміністрації сайту та інших
              користувачів в оману;
            </li>
            <li>
              17.6. Реклама, не узгоджена з адміністрацією сайту, комерційні
              повідомлення, а також повідомлення, які не мають інформаційного
              навантаження і не стосуються тематики ресурсу;
            </li>
            <li> 17.7. Флуд та спам.</li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Модерування.</h2>
          <ul className={style.policySubList}>
            <li>
              18.1. Модератор перевіряє повідомлення (коментарі) після їх
              розміщення на ресурсі у разі надходження на них скарг.
              Поскаржитися на коментар може кожен користувач. Якщо модератор
              вважає, що коментар порушує правила ресурсу, він має право
              видалити його, але зобов’язаний повідомити про це автора у
              будь-який можливий спосіб. Якщо користувач не згоден з рішенням
              модератора, він можете написати про це адміністрації сайту, адреса
              якої зазначена на сайті.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Правила використання матеріалів сайту "Requiem".
          </h2>
          <ul className={style.policySubList}>
            <li>
              19.1. Під використанням розуміється будь-яке відтворення,
              опублікування, копіювання, передрук, поширення, переклад й інші
              способи опрацювання інформаційних продуктів, розміщених на сайті
              «Requiem».
            </li>
            <li>
              19.2. Дозволяється часткове або повне використання матеріалів,
              опублікованих на сайті (у тому числі текстів, фотографій, відео-
              та аудіозаписів), але тільки за умови наявності прямого,
              відкритого для пошукових систем посилання (гіперпосилання) на
              безпосередню адресу матеріалу на «Requiem».
            </li>
            <li>
              19.3. Якщо на запозичених фотографіях та відео присутній логотип
              сайту «Requiem» чи відомості про автора, їх збереження є
              обов’язковим.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Надання доступу до інформації про авторів.
          </h2>
          <ul className={style.policySubList}>
            <li>
              20.1. Адміністрація сайту надає доступ до особистої інформації про
              авторів лише за таких обставин:
            </li>
            <li>20.2. Наявність дозволу самого автора;</li>
            <li>20.3. Наявність відповідних рішень судових органів;</li>
            <li>20.4. Наявність офіційного звернення від СБУ; </li>
            <li>
              20.5. Якщо у адміністрації сайту є підстави вважати, що ця
              інформація сприятиме дотриманню чинного законодавства України,
              розслідуванню потенційних порушень, запобіганню шахрайським та
              іншим злочинним діям.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Кукі, теги та інші ідентифікатори ("Cookies").
          </h2>
          <ul className={style.policySubList}>
            <li>
              21.1. Кукі-файли – це текстові файли, розміщені на Вашому
              комп'ютері або мобільному пристрої для збору стандартної
              інформації в інтернет-журналі та інформації про поведінку
              відвідувачів. Наші Сайти створюють Cookies для кожного сеансу,
              коли Ви відвідуєте їх.
            </li>
            <li>
              21.2. Користувачі можуть бути пов’язані з онлайн-ідентифікаторами
              за допомогою власних пристроїв, додатків, інструментів чи
              протоколів, зокрема IP-адрес, ідентифікаторів «Cookies»
              (реп’яшків) або інших ідентифікаторів, таких як мітки
              радіочастотної ідентифікації. Це може залишити підказки, які,
              особливо в поєднанні з унікальними ідентифікаторами та іншою
              інформацією, отриманою з серверів, Ми можемо використати для
              створення профілів Користувачів та їхньої ідентифікації.
            </li>
            <li>
              21.3. Ми використовуємо Cookies з такою метою:
              <ul className={style.policySubList}>
                <li>
                  21.3.1. Забезпечення реєстрації будь-яких дій, які Ви
                  здійснюєте на Сайті;
                </li>
                <li>
                  21.3.2. Для аналізу трафіку на Сайті, щоб ми могли зробити
                  відповідні покращення.
                </li>
                <li>
                  21.3.3. Пам'ятайте, що Сайт не можна використовувати без
                  Cookies. Якщо Вам потрібна додаткова інформація про цілі
                  використання Нами Cookies, Ви можете подати нам відповідний
                  запит.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Прикінцеві положення.</h2>
          <ul className={style.policySubList}>
            <li>
              22.1. Ця Політика набуває чинності і діє безстроково. Ми маємо
              право оновлювати та змінювати цю Політику
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default Policy;
