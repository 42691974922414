import React from "react";
import { Link } from "react-router-dom";
import { TeamateType } from "./Teamate.types";
import style from "./Teamate.module.css";

const Teamate: React.FC<TeamateType> = ({ name, role, link, photo }) => {
  return (
    <Link to={link} className={style.wrap} target="_blank">
      <div className={style.img}>
        <img src={photo} alt={photo} />
      </div>
      <span className={style.name}>{name}</span>
      <span className={style.role}>{role}</span>
    </Link>
  );
};

export default Teamate;
