import { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./redux/User/types";
import { refreshUser } from "./redux/User/operations";
import { getUserRefreshingStatus } from "./redux/User/selectors";
import Layout from "./components/User/Layout/Layout";
import Loader from "./components/Common/Loader/Loader";
import PrivateRoute from "./components/Common/PrivateRoute/PrivateRoute";
import Home from "./pages/User/HomePage/Home";
import ServicePage from "./pages/Common/ServicePage/ServicePage";
import AdminLayout from "./components/Admin/AdminLayout/AdminLayout";

const AboutUs = lazy(() => import("./pages/User/AboutUsPage/AboutUs"));
const Moderators = lazy(
  () => import("./components/Admin/Moderators/Moderators")
);
const Settings = lazy(() => import("./components/Admin/Settings/Settings"));
const AccountSettings = lazy(
  () => import("./components/Admin/AccountSettings/AccountSettings")
);
const UserDetails = lazy(
  () => import("./components/Admin/UserDetails/UserDetails")
);
const MemoryPageAdminUse = lazy(
  () => import("./pages/Admin/MemoryPage/MemoryPage")
);
const UserPages = lazy(() => import("./components/Admin/Users/UserPages"));
const UserList = lazy(() => import("./components/Admin/UserList/UserList"));
const AdminLogin = lazy(
  () => import("./pages/Admin/AdminLoginPage/AdminLogin")
);
const Dashboard = lazy(() => import("./pages/Admin/Dashboard/Dashboard"));
const AdminHome = lazy(() => import("./components/Admin/AdminHome/AdminHome"));
const FrequentlyAskedQuestion = lazy(() => import("./pages/User/FAQPage/FAQ"));
const PrivacyAndPolicy = lazy(() => import("./pages/User/PrivacyPage/Privacy"));
const TermsPage = lazy(() => import("./pages/User/TermsPage/Terms"));
const NotFoundPage = lazy(() => import("./pages/Common/NotFoundPage/NotFound"));
const UserProfile = lazy(
  () => import("./pages/User/UserProfilePage/UserProfile")
);
const Profile = lazy(() => import("./components/User/Profile/Profile"));
const Memory = lazy(() => import("./components/User/Memory/Memory"));
const Messages = lazy(() => import("./components/Common/Messages/Messages"));
const Notifications = lazy(
  () => import("./components/User/Notifications/Notifications")
);
const MemoryPage = lazy(() => import("./pages/User/MemoryPage/MemoryPage"));
const TemplateMemoryPage = lazy(
  () => import("./pages/User/TemplateMemoryPage/TemplateMemoryPage")
);

function App() {
  const dispatch: AppDispatch = useDispatch();
  const isRefreshing = useSelector(getUserRefreshingStatus);

  useEffect(() => {
    dispatch(refreshUser());
  }, [dispatch]);
  return isRefreshing ? (
    <Loader />
  ) : (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/:id" element={<MemoryPage />} />

          <Route path="memoryPage" element={<MemoryPage />} />

          <Route path="about" element={<AboutUs />} />
          <Route path="privacy-and-policy" element={<PrivacyAndPolicy />} />
          <Route path="terms" element={<TermsPage />} />
          <Route
            path="frequently-asked-question"
            element={<FrequentlyAskedQuestion />}
          />
          <Route path="google" element={<ServicePage />} />
          <Route path="facebook" element={<ServicePage />} />
          <Route
            path="user"
            element={<PrivateRoute redirectTo="/" component={UserProfile} />}
          >
            <Route path="profile" element={<Profile />} />
            <Route
              path="memory/createMemoryPage"
              element={<TemplateMemoryPage />}
            />
            <Route path="memory" element={<Memory />} />
            <Route
              path="memory/createMemoryPage/:id"
              element={<TemplateMemoryPage />}
            />
            <Route path="messages" element={<Messages />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminLogin />} />
          <Route path="memory/:id" element={<MemoryPageAdminUse />} />
          <Route
            path="dashboard"
            element={<PrivateRoute redirectTo="/admin" component={Dashboard} />}
          >
            <Route path="main" element={<AdminHome />} />
            <Route path="pages" element={<UserPages />} />
            <Route path="users" element={<UserList />} />
            <Route path="users/:id" element={<UserDetails />} />
            <Route path="users/:id/message" element={<Messages />} />
            <Route path="settings" element={<Settings />}>
              <Route path="account" element={<AccountSettings />} />
              <Route path="users" element={<UserList />} />
              <Route path="users/:id" element={<UserDetails />} />
              <Route path="moderators" element={<Moderators />} />
              <Route path="moderators/:id" element={<UserDetails />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
