import { createSlice } from "@reduxjs/toolkit";
import { SignUpData } from "../../User/types";

const initialState: SignUpData = {
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  terms: false,
  policy: false,
  timer: 0,
};

const signUpSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    signTerms(state, { payload }) {
      state.terms = payload;
    },
    signPolicy(state, { payload }) {
      state.policy = payload;
    },
    addName(state, { payload }) {
      state.firstName = payload;
    },
    addLastName(state, { payload }) {
      state.lastName = payload;
    },
    addMiddleName(state, { payload }) {
      state.middleName = payload;
    },
    addEmail(state, { payload }) {
      state.email = payload;
    },
    addPhone(state, { payload }) {
      state.phone = payload;
    },
    addTimer(state, { payload }) {
      state.timer = payload;
    },
  },
});

export const signUpReducer = signUpSlice.reducer;

export const {
  signTerms,
  signPolicy,
  addName,
  addLastName,
  addMiddleName,
  addEmail,
  addPhone,
  addTimer,
} = signUpSlice.actions;
