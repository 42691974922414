import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { signUpReducer } from "./User/slices/SignUpSlice";
import { userReducer } from "./User/slices/UserSlice";
import { CheckUserEmailReducer } from "./User/slices/CheckUserEmail";
import { MemoryPages } from "./User/slices/MemotyPagesSlice/MemotyPagesSlice";
import { deletedPhotos } from "./User/slices/deletedPhotos/DeletedPhotosSlice";

export const store = configureStore({
  reducer: {
    signup: signUpReducer,
    user: userReducer,
    findUser: CheckUserEmailReducer,
    pages: MemoryPages,
    deletedPhotos: deletedPhotos,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
});

export const persistor = persistStore(store);
