import { PhoneInput, defaultCountries } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import style from "./Input.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSignUpData, getUser } from "../../../redux/User/selectors";
import { AppDispatch } from "../../../redux/User/types";
import { addPhone } from "../../../redux/User/slices/SignUpSlice";
import { PhoneProps } from "./Input.types";

const currentCountries = defaultCountries.filter(
  (country) =>
    country[0] !== "Belarus" &&
    country[0] !== "Russia" &&
    country[0] !== "Eritrea"
);

const Phone: React.FC<PhoneProps> = ({
  empty,
  setEmpty,
  success,
  setServerError,
  signUp,
}) => {
  const { user } = useSelector(getUser);
  const signUpData = useSelector(getSignUpData);
  const dispatch: AppDispatch = useDispatch();

  const [phoneError, setPhoneError] = useState(false);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [phone, setPhone] = useState(signUp ? signUpData.phone : user.phone);
  const [phoneValidate, setPhoneValidate] = useState(false);
  const [first, setFirst] = useState(true);

  const errorColor = signUp ? "#86181e" : "#CA0000";
  const primaryColor = signUp ? "#03051e" : "#ffffff";

  const isPhoneValid = (phone: string) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handlePhoneInputChange = (value: string) => {
    success(false);
    setEmpty(false);
    setServerError(false);
    if (signUp) dispatch(addPhone(value));
    setPhoneValidate(isPhoneValid(value));
    if (!first === !phone.split(" ")[1]) handlePhoneValidate(value);
    setFirst(false);
    return setPhone(value.replace(/\D/g, ""));
  };

  const handlePhoneValidate = (phone: string) => {
    const emptyCheck = phone.split(" ");
    if (!emptyCheck[1]) {
      success(true);
      setEmpty(true);
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
      setEmpty(false);
    }
    setPhoneValidate(isPhoneValid(phone));
    if (!isPhoneValid(phone)) {
      success(true);
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
    }
    if (!empty && phoneError) success(false);
  };

  return (
    <>
      <div
        className={style.formLabel}
        style={{
          color: signUp ? "#03051e" : "#ffffff",
        }}
      >
        Телефон{!signUp ? "*" : ""}
        <PhoneInput
          preferredCountries={["ua"]}
          countries={currentCountries}
          name="phone"
          countrySelectorStyleProps={{
            buttonStyle: {
              backgroundColor: "transparent",
              border: "none",
              paddingTop: "8px",
            },
            flagStyle: {
              marginLeft: "0",
              marginRight: "8px",
              borderRadius: "1px",
              width: "24px",
              height: "16px",
            },
            dropdownStyleProps: {
              style: {
                width: "250px",
                minHeight: "288px",
                outline: "none",
                paddingLeft: "12px",
                paddingRight: "0",
                paddingTop: "16px",
                paddingBottom: "16px",
                backgroundColor: "#f5f5f5",
                border: "1px solid #93999b",
                borderRadius: "16px",
              },
              listItemFlagStyle: {
                marginRight: "4px",
                borderRadius: "1px",
                width: "24px",
                height: "16px",
              },
              listItemStyle: {
                paddingTop: "6px",
                paddingBottom: "6px",
                display: "flex",
                paddingLeft: "0",
              },
              listItemDialCodeStyle: {
                flexGrow: "1",
                color: "#93999b",
                fontFamily: "Work Sans",
                fontSize: "16px",
                lineHeight: "1.5",
              },
              listItemCountryNameStyle: {
                order: "3",
                width: "60%",
                color: "#3d3e3f",
                fontFamily: "Work Sans",
                fontSize: "16px",
                lineHeight: "1.5",
              },
            },
          }}
          inputStyle={{
            outline: "none",
            border: "none",
            backgroundColor: "transparent",
            color:
              (phoneError && !phoneValidate) || empty
                ? errorColor
                : primaryColor,
            fontFamily: "e-Ukraine Head",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "1.2",
            padding: "0",
          }}
          defaultCountry="ua"
          className={
            !signUp
              ? `${style.formInput} ${style.profileInput} ${style.containerPhoneInput}`
              : `${style.formInput} ${style.containerPhoneInput}`
          }
          style={
            (phoneError && !phoneValidate) || empty
              ? { borderColor: errorColor }
              : phoneValidate
              ? { borderColor: signUp ? "#009955" : "#ffffff" }
              : { borderColor: signUp ? "#93999b" : "#ffffff" }
          }
          value={phone}
          onChange={(_, value) => {
            handlePhoneInputChange(value.inputValue);
          }}
        />
      </div>
      {empty && (
        <p className={style.errorMessage} style={{ color: errorColor }}>
          Це поле є обов'язковим
        </p>
      )}
      {phoneError && !phoneValidate && !empty && (
        <p className={style.errorMessage} style={{ color: errorColor }}>
          Не коректний номер
        </p>
      )}
    </>
  );
};

export default Phone;
