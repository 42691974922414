import { ServerErrorProps } from "./types";
import style from "./ServerError.module.css";

const ServerError: React.FC<ServerErrorProps> = ({ access }) => {
  return (
    <div className={style.wrap}>
      <span className={style.errorMessage}>
        Помилка {access ? "доступу" : "сервера"}
      </span>
    </div>
  );
};

export default ServerError;
