import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, SignUpRequest } from "../../../redux/User/types";
import { getSignUpData } from "../../../redux/User/selectors";
import {
  addEmail,
  addMiddleName,
  addName,
  addPhone,
  addLastName,
  addTimer,
  signPolicy,
  signTerms,
} from "../../../redux/User/slices/SignUpSlice";
import { getUserEmail } from "../../../redux/User/slices/CheckUserEmail";
import { userSignUp } from "../../../redux/User/operations";
import ServiseLogin from "./ServiceLogin/ServiceLogin";
import Loading from "../../Common/Loading/Loading";
import Button from "../../Common/Button/Button";
import ServerError from "../../Common/ServerError/ServerError";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import NameInput from "../InputFields/NameInput";
import PasswordInput from "../InputFields/PasswordInput";
import EmailInput from "../InputFields/EmailInput";
import Phone from "../InputFields/PhoneInput";
import { ModalSignUplProps } from "./Modal.types";
import style from "./Modal.module.css";

const SignUpModal: React.FC<ModalSignUplProps> = ({
  signUpModalHandler,
  loginModalHandler,
  completeSingUpHandler,
  policyModalOpen,
  termsModalOpen,
}): JSX.Element => {
  const signUpData = useSelector(getSignUpData);
  const dispatch: AppDispatch = useDispatch();
  const [emptyName, setEmptyName] = useState(false);
  const [nameError, setNameError] = useState(true);
  const [emptyLastName, setEmptyLastName] = useState(false);
  const [lastNameError, setLastNameError] = useState(true);
  const [emptyMiddleName, setEmptyMiddleName] = useState(false);
  const [middleNameError, setMiddleNameError] = useState(true);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [emptyPhone, setEmptyPhone] = useState(false);
  const [phoneError, setPhoneError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [policyCheckbox, setPolicyCheckbox] = useState(signUpData.policy);
  const [termsCheckbox, setTermsCheckbox] = useState(signUpData.terms);
  const [policyError, setPolicyError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [userError, setUserError] = useState<null | number>(null);
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginBtnClick = () => {
    signUpModalHandler(false);
    loginModalHandler(true);
  };

  const handlePolicyModalOpen = () => {
    signUpModalHandler(false);
    if (policyModalOpen) {
      policyModalOpen(true);
    }
  };

  const handleTermsModalOpen = () => {
    signUpModalHandler(false);
    if (termsModalOpen) {
      termsModalOpen(true);
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setServerError(false);
    const formData = new FormData(e.currentTarget);
    const data: SignUpRequest = {
      firstName: formData.get("firstName") as string,
      middleName: formData.get("middleName") as string,
      lastName: formData.get("lastName") as string,
      phone: formData.get("phone") as string,
      email: formData.get("email") as string,
      password: formData.get("password") as string,
    };
    customCheckboxValidate();

    setEmptyName(!data.firstName);
    setEmptyLastName(!data.lastName);
    setEmptyMiddleName(!data.middleName);
    setEmptyEmail(!data.email);
    setEmptyPassword(!data.password);
    setEmptyPhone(!data.phone.split(" ")[1]);

    if (
      data.firstName &&
      data.middleName &&
      data.lastName &&
      data.phone &&
      data.email &&
      data.password &&
      !userError &&
      !nameError &&
      !lastNameError &&
      !middleNameError &&
      !emailError &&
      !passwordError &&
      !serverError &&
      policyCheckbox &&
      termsCheckbox &&
      !phoneError
    ) {
      data.firstName = data.firstName.trim();
      data.lastName = data.lastName.trim();
      data.middleName = data.middleName.trim();
      handleUserSignUp(data);
    }
  };

  const handleUserSignUp = async (data: SignUpRequest) => {
    dispatch(getUserEmail(data.email));
    dispatch(addTimer(0));
    dispatch(addName(""));
    dispatch(addLastName(""));
    dispatch(addMiddleName(""));
    dispatch(addPhone(""));
    dispatch(addEmail(""));
    dispatch(signPolicy(false));
    dispatch(signTerms(false));
    setIsLoading(true);
    setServerError(false);
    const { payload } = await dispatch(userSignUp(data));
    if (typeof payload === "object") {
      signUpModalHandler(false);
      completeSingUpHandler(true);
    }
    if (payload === 409) {
      setUserError(payload);
    } else {
      setServerError(true);
    }
    setIsLoading(false);
  };

  const customCheckboxValidate = () => {
    if (policyCheckbox) {
      setPolicyError(false);
    } else {
      setPolicyError(true);
    }
    if (termsCheckbox) {
      setTermsError(false);
    } else {
      setTermsError(true);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.modal}>
        <div className={style.signupContentWrap}>
          <h2 className={style.title}>Реєстрація</h2>
          <form noValidate className={style.form} onSubmit={handleSubmitForm}>
            <ul className={style.inputList}>
              <li className={style.inputItem}>
                <NameInput
                  title="Прізвище"
                  fieldName="lastName"
                  signUp={true}
                  success={setLastNameError}
                  empty={emptyLastName}
                  setEmpty={setEmptyLastName}
                  setServerError={setServerError}
                />
              </li>
              <li className={style.inputItem}>
                <NameInput
                  title="Ім'я"
                  fieldName="firstName"
                  signUp={true}
                  success={setNameError}
                  empty={emptyName}
                  setEmpty={setEmptyName}
                  setServerError={setServerError}
                />
              </li>
              <li className={style.inputItem}>
                <NameInput
                  fieldName="middleName"
                  signUp={true}
                  success={setMiddleNameError}
                  empty={emptyMiddleName}
                  setEmpty={setEmptyMiddleName}
                  setServerError={setServerError}
                />
              </li>
              <li className={style.inputItem}>
                <EmailInput
                  userError={userError}
                  errorText="Пошта вже зареєстрована"
                  signUp={true}
                  setEmptyEmail={setEmptyEmail}
                  success={setEmailError}
                  emptyEmail={emptyEmail}
                  setUserError={setUserError}
                  setServerError={setServerError}
                />
              </li>
              <li className={style.inputItem}>
                <Phone
                  signUp={true}
                  success={setPhoneError}
                  empty={emptyPhone}
                  setEmpty={setEmptyPhone}
                  setServerError={setServerError}
                />
              </li>
              <li className={style.inputItem}>
                <PasswordInput
                  success={setPasswordError}
                  emptyPassword={emptyPassword}
                  setEmptyPassword={setEmptyPassword}
                  setServerError={setServerError}
                />
              </li>
            </ul>
            <div className={style.checkboxWrapper}>
              <CustomCheckbox
                policyModalOpen={handlePolicyModalOpen}
                termsModalOpen={handleTermsModalOpen}
                policyError={policyError}
                termsError={termsError}
                policyCheckbox={policyCheckbox}
                termsCheckbox={termsCheckbox}
                setTermsCheckbox={setTermsCheckbox}
                setPolicyCheckbox={setPolicyCheckbox}
              />
            </div>
            <Button title={"Зареєструватись"} modal />
            {serverError && <ServerError />}
          </form>
          <ServiseLogin />
          <div className={style.modalBottomInfo}>
            <p>Вже маєте існуючий профіль?</p>
            <button onClick={handleLoginBtnClick} className={style.navigateBtn}>
              Увійти
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpModal;
