import axios, { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ChangePasswordType,
  CreateNewPassServesType,
  ForgotPasswordType,
  LogInRequest,
  LogInResponse,
  MessageResponse,
  Notification,
  RemoveUserResponse,
  ResetPasswordType,
  SignUpRequest,
  SignUpResponse,
  UpdateUser,
  VerifyRequest,
} from "../User/types";
import { PageResponse } from "./slices/MemotyPagesSlice/MemotyPagesSlice.types";
import { Message } from "../../components/Common/Messages/types";

axios.defaults.baseURL = "https://api.requiem.army/api";

const token = {
  set(token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = "";
  },
};

//==signup==

export const userSignUp = createAsyncThunk<SignUpResponse, SignUpRequest>(
  "user/signup",
  async (user: SignUpRequest, thunkAPI) => {
    try {
      const { data } = await axios.post<SignUpResponse>("/auth/signup", user);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==login==

export const userLogIn = createAsyncThunk<LogInResponse, LogInRequest>(
  "user/signin",
  async (user: LogInRequest, thunkAPI) => {
    try {
      const { data } = await axios.post<LogInResponse>("/auth/signin", user);
      token.set(data.token);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==logout==

export const userLogOut = createAsyncThunk<MessageResponse>(
  "user/logout",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.post<MessageResponse>("/auth/logout");
      token.unset();
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==refresh/current user==

export const refreshUser = createAsyncThunk<LogInResponse>(
  "user/current",
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const persistedToken = state.user.token;
    if (persistedToken === null) {
      return thunkAPI.rejectWithValue(null);
    }

    token.set(persistedToken);
    try {
      const { data } = await axios.get<LogInResponse>("/auth/current");
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==delete user==

export const removeUser = createAsyncThunk<MessageResponse>(
  "user/deleteUser",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.delete<MessageResponse>("/auth/deleteUser");
      token.unset();
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const delayedRemoveUser = createAsyncThunk<RemoveUserResponse>(
  "user/delayedDeleteUser",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.patch<RemoveUserResponse>(
        "/auth/userDelete"
      );
      token.unset();
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const cancelRemoveUser = createAsyncThunk<MessageResponse>(
  "user/cancelDeleteUser",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.patch<MessageResponse>("/auth/cancelDelete");
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==verify user==

export const userVerify = createAsyncThunk(
  "user/verify",
  async (otp: VerifyRequest, thunkAPI) => {
    try {
      const { data } = await axios.post<LogInResponse>("/auth/verify", otp);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const userResendVerify = createAsyncThunk(
  "user/resendVerifyEmail",
  async (email: { email: string }, thunkAPI) => {
    try {
      const { data } = await axios.post<ForgotPasswordType>(
        "/auth/resendVerifyEmail",
        email
      );
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==forget password==

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email: ForgotPasswordType, thunkAPI) => {
    try {
      const { data } = await axios.post<ForgotPasswordType>(
        "/auth/forgotPassword",
        email
      );
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==reset password==

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data: ResetPasswordType, thunkAPI) => {
    try {
      const res = await axios.patch<MessageResponse>(
        "/auth/resetPassword",
        data
      );
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==new OTP==

export const sendNewOtp = createAsyncThunk(
  "user/sendOtp",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get<MessageResponse>("/auth/sendOtp");
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//== user==

export const updateUser = createAsyncThunk(
  "user/update",
  async (user: UpdateUser, thunkAPI) => {
    try {
      const { data } = await axios.patch<UpdateUser>("/auth/user", user);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==change Password==

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (paswords: ChangePasswordType, thunkAPI) => {
    try {
      const res = await axios.patch<MessageResponse>(
        "/auth/changePassword",
        paswords
      );
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createNewPass = createAsyncThunk(
  "user/createNewPass",
  async (passwords: CreateNewPassServesType, thunkAPI) => {
    try {
      const res = await axios.patch<MessageResponse>(
        "/auth/createPassword",
        passwords
      );
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==create Memory Page==

export const createMemoryPage = createAsyncThunk<PageResponse, FormData>(
  "user/createMemoryPages",
  async (form: FormData, thunkAPI) => {
    try {
      const { data } = await axios.post<PageResponse>("/pages", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==get all Memory Pages==

export const getAllMemoryPages = createAsyncThunk(
  "user/getAllMemoryPages",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get("/pages");
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==get Memory Pages by Id==

export const getMemoryPageById = createAsyncThunk(
  "user/getMemoryPageById",
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get(`/pages/${id}`);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==delete Memory Page==

export const deleteMemoryPage = createAsyncThunk(
  "user/deleteMemoryPage",
  async (id: string, thunkAPI) => {
    try {
      const res = await axios.delete(`/pages/${id}`);
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deteleMemoryPageNew = createAsyncThunk(
  "user/deleteMemoryPageNew",
  async (id: string, thunkAPI) => {
    try {
      const res = await axios.patch(`/pages/${id}/delete`);
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==cancel delete memory Page==

export const cancelDeleteMemoryPage = createAsyncThunk(
  "user/cancelDeleteMemoryPage",
  async (id: string, thunkAPI) => {
    try {
      const res = await axios.patch(`pages/${id}/cancelDelete`);
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//==edit Memory Pages by Id==

export const editMemoryPageById = createAsyncThunk(
  "user/editMemoryPageById",
  async ({ id, form }: { id: string; form: FormData }, thunkAPI) => {
    try {
      const res = await axios.patch(`/pages/${id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.status;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==send message from cabinet==

export const sendMessage = createAsyncThunk(
  "user/message",
  async (message: Message, thunkAPI) => {
    try {
      const { data } = await axios.post<MessageResponse>(
        "/auth/user/message",
        message
      );
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==notifications==

export const changeNotificationsStatus = createAsyncThunk(
  "user/notifications",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.patch<MessageResponse>(
        "/auth/user/notifications"
      );
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "user/getNotifications",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get<Notification[]>(
        "/auth/user/notifications"
      );
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);
