import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSignUpData } from "../../../redux/User/selectors";
import { CountdownProps } from "../../UI/Modals/Modal.types";
import style from "./Timer.module.css";

const Timer: React.FC<CountdownProps> = ({ minutes }) => {
  const { timer } = useSelector(getSignUpData);
  const currentSeconds = Math.floor(new Date().getTime() / 1000);
  const time = timer ? currentSeconds - timer : null;

  const [seconds, setSeconds] = useState(time ? 120 - time : minutes);
  useEffect(() => {
    const timerId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 0) {
          clearInterval(timerId);
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [minutes]);

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    if (seconds <= 0) {
      return "00:00";
    }
    return `${mins < 10 ? "0" + mins : mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <p>
      Залишилось часу:{" "}
      <span className={formatTime(seconds) === "00:00" ? style.error : ""}>
        {formatTime(seconds)}
      </span>
    </p>
  );
};

export default Timer;
