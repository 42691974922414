import React, { useState } from "react";
import Modal from "../ModalTemplate/Modal";
import SignUpModal from "./SignUpModal";
import EndSingUpModal from "./CompleteSingUp";
import ForgetPassword from "./ForgetPasswordModal";
import CheckYourEmail from "./CheckYourEmailModal";
import NewPasswordModal from "./NewPasswordModal";
import PolicyModal from "./PolicyModal";
import TermsModal from "./TermsModal";
import SignUpSuccessModal from "./SignUpSuccessModal";
import ChangedPassSuccess from "./ChangedPassSuccessModal";
import LoginModal from "./LoginModal";
import NewVerificationCode from "./NewVerificationCodeModal";
import CreateNewPasswordModal from "./CreateNewPasswordModal";
import CreateNewPasswordServicesModal from "./CreateNewPasswordServicesModal";
import UpdateSuccessModal from "./UpdateSuccessModal";
import ModalFileSizeError from "./ModalFileSizeError/ModalFileSizeError";
import RemoveMemoryPageModal from "./RemoveMemoryPageModal";
import { ModalsWrapperProps } from "./Modal.types";

const ModalsWrapper: React.FC<ModalsWrapperProps> = ({
  openModalWrapperLogic,
  openLoginModal,
  openSignUpModal,
  fogetPassModal,
  openCreatePassModal,
  termsModal,
  policyModal,
  termsProfileModal,
  policyProfileModal,
  openServicesCreatePassword,
  updateSuccess,
  finishUserChahgePass,
  servicesPass,
  fileSizeError,
  templateMemoryPageTitle,
  templateMemoryPageText,
  openRemoveMemoryPage,
  removeMemoryPage,
}): JSX.Element => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(
    openLoginModal || false
  );
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(
    openSignUpModal || false
  );

  const [isEndSingUpModalOpen, setIsEndSingUpModalOpen] = useState(false);
  const [openCreateNewPassModal, setOpenCreateNewPassModal] = useState(
    openCreatePassModal || false
  );
  const [openCreateNewPassServicesModal, setOpenCreateNewPassServicesModal] =
    useState(openServicesCreatePassword || false);
  const [openNewPassModal, setOpenNewPassModal] = useState(false);
  const [openPolicyModal, setOpenPolicyModal] = useState(policyModal || false);
  const [openTermsModal, setOpenTermsModal] = useState(termsModal || false);
  const [openProfilePolicyModal, setOpenProfilePolicyModal] = useState(
    policyProfileModal || false
  );
  const [openProfileTermsModal, setOpenProfileTermsModal] = useState(
    termsProfileModal || false
  );
  const [successModal, setSuccessModal] = useState(false);
  const [openFogetPassModal, setOpenFogetPassModal] = useState(
    fogetPassModal || false
  );
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openChangedPassModal, setOpenChangedPassModal] = useState(false);
  const [openNewVerificationModal, setOpenNewVerificationModal] =
    useState(false);
  const [isChangeModalKey, setIsChangeModalKey] = useState(false);
  const [openFileSizeError, setOpenFileSizeError] = useState(
    fileSizeError || false
  );

  const [deleteMemoryPage, setDeleteMemoryPage] = useState<boolean>(
    openRemoveMemoryPage || false
  );

  const handlePolicyModalOpening = () => {
    setIsSignUpModalOpen(!isSignUpModalOpen);
    setOpenPolicyModal(!openPolicyModal);
    document.body.classList.add("lock");
  };

  const handleTermsModalOpening = () => {
    setIsSignUpModalOpen(!isSignUpModalOpen);
    setOpenTermsModal(!openTermsModal);
  };

  const handleProfilePolicyModalOpening = () => {
    setOpenProfilePolicyModal(!openProfilePolicyModal);
    closeModal();
  };

  const handleProfileTermsModalOpening = () => {
    setOpenProfileTermsModal(!openProfileTermsModal);
    closeModal();
  };

  const closeModal = () => {
    openModalWrapperLogic(false);
    document.body.classList.remove("lock");
  };

  return (
    <>
      {/* == login modal == */}
      {isLoginModalOpen && (
        <Modal
          modalIsOpen={setIsLoginModalOpen}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <LoginModal
            completeSingUpHandler={setIsEndSingUpModalOpen}
            signUpModalHandler={setIsSignUpModalOpen}
            loginModalHandler={setIsLoginModalOpen}
            forgetPassModalHandler={setOpenFogetPassModal}
            modalWrapperIsOpen={openModalWrapperLogic}
          />
        </Modal>
      )}
      {/* == sign up modal == */}
      {isSignUpModalOpen && (
        <Modal
          modalIsOpen={setIsSignUpModalOpen}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <SignUpModal
            completeSingUpHandler={setIsEndSingUpModalOpen}
            signUpModalHandler={setIsSignUpModalOpen}
            loginModalHandler={setIsLoginModalOpen}
            policyModalOpen={setOpenPolicyModal}
            termsModalOpen={setOpenTermsModal}
          />
        </Modal>
      )}
      {/* == complete sign up == */}
      {isEndSingUpModalOpen && (
        <Modal
          modalIsOpen={setIsEndSingUpModalOpen}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <EndSingUpModal
            signUpModalHandler={setIsSignUpModalOpen}
            endSingUpHundler={setIsEndSingUpModalOpen}
            getNewVerificationCode={setOpenNewVerificationModal}
            successModalOpen={setSuccessModal}
            isChangeModalKey={setIsChangeModalKey}
          />
        </Modal>
      )}
      {/* == forget password == */}
      {openFogetPassModal && (
        <Modal
          modalIsOpen={setOpenFogetPassModal}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <ForgetPassword
            fogetPassModalHandler={setOpenFogetPassModal}
            checkEmailModalHandler={setOpenEmailModal}
            clickBackModalHandler={
              openCreatePassModal
                ? setOpenCreateNewPassModal
                : setIsLoginModalOpen
            }
          />
        </Modal>
      )}
      {/* == email modal == */}
      {openEmailModal && (
        <Modal
          modalIsOpen={setOpenEmailModal}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <CheckYourEmail
            newPassModalHandler={setOpenNewPassModal}
            checkEmailModalHandler={setOpenEmailModal}
          />
        </Modal>
      )}
      {/* == new password modal == */}
      {openNewPassModal && (
        <Modal
          modalIsOpen={setOpenNewPassModal}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <NewPasswordModal
            changedPassModalHandler={setOpenChangedPassModal}
            newPassModalHandler={setOpenNewPassModal}
            getNewVerificationCode={setOpenNewVerificationModal}
            isChangeModalKey={setIsChangeModalKey}
          />
        </Modal>
      )}
      {/* == verivy modal == */}
      {openNewVerificationModal && (
        <Modal
          modalIsOpen={setSuccessModal}
          modalWrapperIsOpen={openModalWrapperLogic}
          modalNewVerificationIsOpen={openNewVerificationModal}
        >
          <NewVerificationCode
            getNewVerificationCode={setOpenNewVerificationModal}
            newPassModalHandler={setOpenNewPassModal}
            endSingUpHundler={setIsEndSingUpModalOpen}
            isChangeModalKey={isChangeModalKey}
          />
        </Modal>
      )}
      {/* == change password == */}
      {openChangedPassModal && (
        <Modal
          modalIsOpen={setOpenChangedPassModal}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <ChangedPassSuccess
            loginModalHandler={setIsLoginModalOpen}
            changedPassModalHandler={setOpenChangedPassModal}
            finishChangePassword={finishUserChahgePass || false}
            servicesPassIsCompleted={servicesPass || false}
            closeModal={closeModal}
          />
        </Modal>
      )}
      {/* == policy modal == */}
      {openPolicyModal && (
        <Modal
          modalIsOpen={handlePolicyModalOpening}
          modalWrapperIsOpen={openModalWrapperLogic}
          policyAndTerms={handlePolicyModalOpening}
        >
          <PolicyModal modalIsOpen={handlePolicyModalOpening} />
        </Modal>
      )}
      {openProfilePolicyModal && (
        <Modal
          modalIsOpen={handleProfilePolicyModalOpening}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <PolicyModal modalIsOpen={handleProfilePolicyModalOpening} />
        </Modal>
      )}
      {/* == terms modal == */}
      {openTermsModal && (
        <Modal
          modalIsOpen={handleTermsModalOpening}
          modalWrapperIsOpen={openModalWrapperLogic}
          policyAndTerms={handleTermsModalOpening}
        >
          <TermsModal modalIsOpen={handleTermsModalOpening} />
        </Modal>
      )}
      {openProfileTermsModal && (
        <Modal
          modalIsOpen={handleProfileTermsModalOpening}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <TermsModal modalIsOpen={handleProfileTermsModalOpening} />
        </Modal>
      )}
      {/* == sign up success modal == */}
      {successModal && (
        <Modal
          modalIsOpen={setSuccessModal}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <SignUpSuccessModal
            modalIsOpen={setSuccessModal}
            loginModalHandler={setIsLoginModalOpen}
          />
        </Modal>
      )}
      {/* == update success modal == */}
      {updateSuccess && (
        <Modal
          modalIsOpen={updateSuccess?.modalIsOpen}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <UpdateSuccessModal
            modalIsOpen={updateSuccess?.modalIsOpen}
            title={updateSuccess.title}
            subtitle={updateSuccess.subtitle}
            removeProfile={updateSuccess.removeProfile}
            btnTitle={updateSuccess.btnTitle}
            logic={updateSuccess.logic}
            facebookLogic={updateSuccess.facebookLogic}
          />
        </Modal>
      )}
      {/* == create new password == */}
      {openCreateNewPassModal && (
        <Modal
          modalIsOpen={setOpenCreateNewPassModal}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <CreateNewPasswordModal
            forgetPassModalHandler={setOpenFogetPassModal}
            createNewPassModalHandler={setOpenCreateNewPassModal}
            changedPassModalHandler={setOpenChangedPassModal}
          />
        </Modal>
      )}
      {/* == create new password google/facebook == */}
      {openCreateNewPassServicesModal && (
        <Modal
          modalIsOpen={setOpenCreateNewPassServicesModal}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <CreateNewPasswordServicesModal
            createServicePassHandler={setOpenCreateNewPassServicesModal}
            changedPassModalHandler={setOpenChangedPassModal}
          />
        </Modal>
      )}
      {/* == file size error & user is blocked == */}
      {openFileSizeError && (
        <Modal
          modalIsOpen={setOpenFileSizeError}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <ModalFileSizeError
            closeModal={setOpenFileSizeError}
            title={templateMemoryPageTitle ? templateMemoryPageTitle : ""}
            text={templateMemoryPageText ? templateMemoryPageText : ""}
          />
        </Modal>
      )}
      {/* == delete memory page == */}
      {deleteMemoryPage && removeMemoryPage && (
        <Modal
          modalIsOpen={setDeleteMemoryPage}
          modalWrapperIsOpen={openModalWrapperLogic}
        >
          <RemoveMemoryPageModal removeMemoryPage={removeMemoryPage} />
        </Modal>
      )}
    </>
  );
};

export default ModalsWrapper;
