import Button from "../../Common/Button/Button";
import { UpdateModalProps } from "./Modal.types";
import style from "./Modal.module.css";
import ServiceLogin from "./ServiceLogin/ServiceLogin";

const UpdateSuccessModal: React.FC<UpdateModalProps> = ({
  modalIsOpen,
  title,
  subtitle,
  removeProfile,
  btnTitle,
  logic,
  facebookLogic,
}) => {
  return (
    <div className={style.modal}>
      <div className={`${style.successModalWrap} ${style.deleteProfileInner}`}>
        <h2
          className={style.title}
          style={{ marginBottom: !subtitle ? "32px" : "" }}
        >
          {title}
        </h2>
        {subtitle && (
          <p
            className={style.text}
            style={{
              marginBottom: btnTitle ? "32px" : "0px",
              maxWidth: facebookLogic ? "376px" : "",
              marginLeft: facebookLogic ? "auto" : "",
              marginRight: facebookLogic ? "auto" : "",
            }}
          >
            {subtitle}
          </p>
        )}
        {btnTitle && (
          <div
            style={{ marginBottom: facebookLogic ? "32px" : "" }}
            onClick={() => {
              document.body.classList.remove("lock");
              if (logic) logic();
              modalIsOpen(false);
            }}
          >
            <Button title={btnTitle} modal removeProfile={removeProfile} />
          </div>
        )}
        {facebookLogic && <ServiceLogin facebookError={true} />}
      </div>
    </div>
  );
};

export default UpdateSuccessModal;
