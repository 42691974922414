import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatus } from '../../../redux/User/selectors';
import { getNotifications } from '../../../redux/User/operations';
import { AppDispatch } from '../../../redux/User/types';
import ModalsWrapper from '../../UI/Modals/ModalsWrapper';
import icons from '../../../assets/icons.svg';
import style from './Header.module.css';
import { scrollToTop } from '../../../utils/scrollToTop';

const Header: React.FC = (): JSX.Element => {
  const [openLoginModalWrapper, setOpenLoginModalWrapper] = useState(false);
  const [navVisibility, setNavVisibility] = useState(false);
  const [isHorizontal, setIsHorizontal] = useState(false);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isloggedIn = useSelector(getUserStatus);

  const mobileMenu = window.matchMedia('(min-width: 768px)').matches;
  const mobileNavigate = window.matchMedia('(max-width: 767px)').matches;

  const getCurrentNotifocations = async () => {
    try {
      await dispatch(getNotifications());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsHorizontal(window.matchMedia('(orientation: landscape)').matches);
    };

    handleOrientationChange();
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  const openModal = () => {
    setNavVisibility(false);
    setOpenLoginModalWrapper(true);
    document.body.classList.add('lock');
  };

  const handleBodyLock = () => {
    if (navVisibility) {
      document.body.classList.remove('lock');
    } else {
      document.body.classList.add('lock');
    }
  };

  const handleNavLinkClick = () => {
    if (mobileNavigate) {
      handleBodyLock();
    }
    setNavVisibility(false);
  };

  const handleActiveElementClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    event.currentTarget.blur();
  };

  return (
    <>
      <header className={style.wrapper}>
        <div className={style.inner}>
          <Link
            className={style.logoLink}
            to='.'
            onClick={(e) => {
              document.body.classList.remove('lock');
              handleActiveElementClick(e);
              setNavVisibility(false);
            }}
            aria-label='Logo'
          >
            <svg className={style.logoIcon}>
              <use href={`${icons}#logo`} />
            </svg>
          </Link>
          <button
            aria-label='Open navigate menu'
            className={style.burgerWrapper}
            onClick={() => {
              handleBodyLock();
              setNavVisibility(!navVisibility);
              scrollToTop();
            }}
          >
            <span
              className={style.burger}
              style={{
                transform: navVisibility ? 'rotate(-45deg)' : 'rotate(0deg)',
                transformOrigin: 'top right',
                width: navVisibility ? '29px' : '32px',
              }}
            ></span>
            <span
              className={style.burger}
              style={{ opacity: navVisibility ? '0' : '1' }}
            ></span>
            <span
              className={style.burger}
              style={{
                transform: navVisibility ? 'rotate(45deg)' : 'rotate(0deg)',
                transformOrigin: 'bottom right',
                width: navVisibility ? '29px' : '32px',
              }}
            ></span>
          </button>
          <nav
            className={style.navigate}
            style={{
              opacity: navVisibility || mobileMenu ? '1' : '0',
              visibility: navVisibility || mobileMenu ? 'visible' : 'hidden',
            }}
          >
            <div
              className={
                isHorizontal
                  ? `${style.navigateInner} ${style.navigateHorizontal}`
                  : style.navigateInner
              }
            >
              <p className={style.copyright}>
                &#169; 2024
                <Link
                  className={style.copyrightlink}
                  to='https://junfolio.top/'
                  target='_blank'
                  onClick={(e) => {
                    handleActiveElementClick(e);
                  }}
                >
                  Junfolio
                </Link>
                ,
                <Link
                  className={style.copyrightlink}
                  to='https://webjungle.top/uk'
                  target='_blank'
                  onClick={(e) => {
                    handleActiveElementClick(e);
                  }}
                >
                  WebJungle.
                </Link>
                <span>За підтримки БФ «Кластер розвитку та допомоги»</span>
              </p>
              <ul className={style.socialList}>
                <li>
                  <Link
                    to='https://www.facebook.com/profile.php?id=61564992353790'
                    target='_blank'
                    aria-label='Facebook'
                  >
                    <svg width={40} height={40}>
                      <use href={`${icons}#facebook_header`} />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link
                    to='https://www.instagram.com/requiemarmy/?igsh=d2NuOWhpaWQ3dDJh&utm_source=qr'
                    target='_blank'
                    aria-label='Instagram'
                  >
                    <svg width={40} height={40}>
                      <use href={`${icons}#instagram_header`} />
                    </svg>
                  </Link>
                </li>
              </ul>
              <ul
                className={style.navigateList}
                style={{
                  flexDirection: isHorizontal || mobileMenu ? 'row' : 'column',
                }}
              >
                {isloggedIn && (
                  <li
                    className={
                      isHorizontal
                        ? `${style.navItem}`
                        : `${style.navItem} ${style.navItemHorizontal}`
                    }
                  >
                    <NavLink
                      to='/user/memory'
                      className={`${style.navLink} general`}
                      onClick={() => {
                        handleNavLinkClick();
                        getCurrentNotifocations();
                      }}
                    >
                      Сторінки пам'яті
                    </NavLink>
                  </li>
                )}
                <li
                  className={
                    isHorizontal
                      ? `${style.navItem}`
                      : `${style.navItem} ${style.navItemHorizontal}`
                  }
                >
                  <NavLink
                    to='about'
                    className={`${style.navLink} general`}
                    onClick={handleNavLinkClick}
                  >
                    Про проєкт
                  </NavLink>
                </li>
                <li
                  className={
                    isHorizontal
                      ? `${style.navItem}`
                      : `${style.navItem} ${style.navItemHorizontal}`
                  }
                >
                  <NavLink
                    to='frequently-asked-question'
                    className={`${style.navLink} general`}
                    onClick={handleNavLinkClick}
                  >
                    Питання
                  </NavLink>
                </li>
              </ul>
              <button
                className={style.loginBtn}
                onClick={
                  isloggedIn
                    ? (e) => {
                        handleActiveElementClick(e);
                        handleNavLinkClick();
                        navigate('/user/profile');
                      }
                    : (e) => {
                        handleActiveElementClick(e);
                        openModal();
                      }
                }
              >
                {isloggedIn ? <span>Мій кабінет</span> : <span>Увійти</span>}
              </button>
            </div>
          </nav>
        </div>
      </header>
      {openLoginModalWrapper && (
        <ModalsWrapper
          openModalWrapperLogic={setOpenLoginModalWrapper}
          openLoginModal
        />
      )}
    </>
  );
};

export default Header;
