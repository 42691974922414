import { Notification, RootState, SignUpData, User } from "./types";

export const getSignUpData = (state: { signup: SignUpData }): SignUpData =>
  state.signup;
export const getUser = (state: { user: User }): User => state.user;
export const getUserRole = (state: { user: User }): string =>
  state.user.user.role;
export const getUserNotifications = (state: { user: User }): Notification[] =>
  state.user.user.notifications;
export const getUserStatus = (state: { user: User }): boolean =>
  state.user.isLoggedIn;
export const getUserRefreshingStatus = (state: { user: User }): boolean =>
  state.user.isRefreshing;
export const getUserVerifyStatus = (state: { user: User }): boolean =>
  state.user.isVerify;
export const getUserError = (state: { user: User }): null | number =>
  state.user.error;
export const checkUserEmail = (state: RootState): string =>
  state.findUser.email;
export const getCurrentEmail = (state: RootState): string =>
  state.user.user.email;
export const getHasPassword = (state: RootState) => state.user.user.hasPassword;
