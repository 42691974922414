import { PolicyProps } from "./types";
import style from "./Policy.module.css";

const Terms: React.FC<PolicyProps> = ({ modal }): JSX.Element => {
  const subtitleStyles = modal
    ? `${style.subtitle} ${style.subtitleModal}`
    : `${style.subtitle}`;
  const policyItemStyles = modal
    ? `${style.policyItem} ${style.policyItemModal}`
    : `${style.policyItem}`;

  return (
    <div className={style.content} style={{ padding: modal ? "0px" : "" }}>
      {!modal && <h1 className={style.title}>Правила користування сайтом.</h1>}
      <ol className={style.policyList}>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Загальні умови.</h2>
          <ul className={style.policySubList}>
            <li>
              1.1. Використовуючи Сайт Requiem, ви погоджуєтеся дотримуватися
              всіх умов, викладених у цих Правилах користування сайтом (далі –
              «Правила»), включаючи реєстрацію облікового запису. Ці Правила є
              юридично обов'язковою угодою між користувачем і власником сайту
              (далі - адміністрація), предметом якої є надання користувачеві
              послуг з використання Сайту і його сервісів.
            </li>
            <li>
              1.2. Ми можемо змінювати умови цих Правил у будь-який час. Зміни
              набирають чинності негайно після їх оприлюднення на Сайті.
            </li>
            <li>
              1.3. Якщо будь-які умови цих Правил або зміни до них неприйнятні
              для вас, ви можете не розпочинати користування Сайтом або
              припинити дію вашого облікового запису шляхом видалення за
              допомогою функціоналу, розміщеного в особистому кабінеті.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Контент користувачів.</h2>
          <ul className={style.policySubList}>
            <li>
              2.1. Ви погоджуєтеся користуватися Сайтом виключно з законною
              метою, не порушуючи права інших користувачів та не обмежуючи їх
              доступу до Сайту.
            </li>
            <li>
              2.2. Забороняється завантажувати, розповсюджувати та публікувати
              контент наклепницького, образливого, непристойного,
              порнографічного чи іншого незаконного характеру; рекламні та
              комерційні повідомлення, а також повідомлення, які не стосуються
              тематики ресурсу.
            </li>
            <li>
              2.3. Будьте ввічливі. Не погрожуйте та не ображайте інших
              користувачів, не публікуйте спам. Використовуйте шанобливу мову,
              не дискримінуйте на основі раси, релігії, національності, статі,
              сексуальних уподобань, віку, регіону, інвалідності тощо. Ворожнеча
              є підставою для негайного припинення доступу до Сайту.
            </li>
            <li>
              2.4. Ви визнаєте, що будь-які матеріали, поширені вами (наприклад,
              контент, створений вами: повідомлення, текст, відео, аудіо,
              фотографії), можуть бути видалені або змінені адміністрацією. Ви
              відмовляєтесь від будь-яких прав, пов'язаних із цими матеріалами.
            </li>
            <li>
              2.5. Ви несете повну відповідальність за зміст ваших публікацій.
              Адміністрація не контролює кожен матеріал і не несе
              відповідальності за їх зміст. Ми залишаємо за собою право
              видаляти, переміщувати або редагувати матеріал, який адміністрація
              вважає образливим, наклепницьким, непристойним або неприйнятним.
            </li>
            <li>
              2.6. Ви погоджуєтесь на відображення і публікацію на Сайті
              створеного вами матеріалу, а також у рекламних цілях за межами
              Сайту.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Заяви та гарантії.</h2>
          <ul className={style.policySubList}>
            <li>
              3.1. Ви гарантуєте, що матеріали, представлені через ваш профіль,
              не порушують права третіх осіб, включаючи авторські права, товарні
              знаки, приватність або інші особисті чи майнові права.
            </li>
            <li>
              3.2. Ви погоджуєтесь відшкодувати збитки та захистити
              адміністрацію від будь-якої відповідальності та витрат, включаючи
              судові витрати, що виникли через порушення цих Правил, гарантій
              або угод вами або користувачем вашого облікового запису.
            </li>
            <li>
              3.3. Адміністрація не гарантує точності або надійності будь-якої
              завантаженої або поширеної інформації через облікові записи
              користувачів. Матеріали Сайту поширюються "як є", без будь-яких
              гарантій. Ви визнаєте, що користуєтеся Сайтом на свій страх і
              ризик.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Реєстрація та безпека.</h2>
          <ul className={style.policySubList}>
            <li>
              4.1. Під час реєстрації ви створюєте облікові дані для входу,
              включаючи пароль та електронну пошту. Відомості про себе повинні
              бути точними і оновленими.
            </li>
            <li>
              4.2. Реєстрація дійсна для одного користувача. Ви не можете
              ділитися своїми обліковими даними для входу з іншими. Ми можемо
              скасувати ваш доступ до Сайту, якщо ви поділитеся обліковими
              даними. Ви несете відповідальність за збереження конфіденційності
              пароля.
            </li>
            <li>
              4.3. Повідомляйте нас про будь-яке несанкціоноване використання
              вашого облікового запису або порушення безпеки.
            </li>
            <li>
              4.4. Ви несете відповідальність за будь-яке використання або
              діяльність на вашому обліковому запису на Сайті. Шахрайська,
              образлива або протизаконна діяльність може бути підставою для
              припинення вашого облікового запису.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>
            Зв'язок між Сайтом і користувачами.
          </h2>
          <ul className={style.policySubList}>
            <li>
              5.1. Ви надаєте право адміністрації відправляти на вашу електронну
              пошту інформацію про зміни або доповнення послуг на Сайті.
            </li>
            <li>
              5.2. Адміністрація може зв'язуватися з вами по електронній пошті
              для участі в опитуваннях користувачів з метою покращення
              функціоналу Сайту. Інформація, отримана в таких опитуваннях, не
              буде передаватися третім особам, за винятком узагальнених
              знеособлених даних.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Припинення доступу до Сайту.</h2>
          <ul className={style.policySubList}>
            <li>
              6.1. Ви можете припинити дію облікового запису в будь-який момент
              за допомогою функціоналу в профілі користувача. Підтвердження про
              видалення облікового запису буде надіслане на електронну пошту, і
              ваш доступ буде припинений через 14 днів.
            </li>
            <li>
              6.2. Адміністрація надає можливість зареєстрованому користувачу
              відкликати заяву про видалення облікового запису протягом 14 днів.
            </li>
            <li>
              6.3. Всі матеріали, створені користувачем, будуть видалені разом з
              обліковим записом через 14 днів без можливості поновлення. 6.4.
              Адміністрація може видалити або призупинити доступ до Сайту або
              його частини з будь-якої причини, включаючи порушення або
              невиконання цих Правил.
            </li>
          </ul>
        </li>
        <li className={policyItemStyles}>
          <h2 className={subtitleStyles}>Різне.</h2>
          <ul className={style.policySubList}>
            <li>
              7.1. Ці Правила застосовуються відповідно до законодавства
              України. Будь-які спори щодо реалізації цих Правил будуть
              вирішуватися в судовому порядку згідно чинного законодавства
              України.
            </li>
            <li>
              7.2. Листування здійснюється за допомогою електронної адреси,
              вказаної на Сайті.
            </li>
            <li>
              7.3. Ви погоджуєтесь негайно повідомляти про будь-які порушення
              авторських прав щодо матеріалів Сайту.
            </li>
            <li>
              7.4. Адміністрація сайту не несе відповідальності за збитки,
              понесені в результаті використання або невикористання інформації
              та сервісів Requiem.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default Terms;
