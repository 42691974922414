import React from "react";
import Button from "../../Common/Button/Button";
import { ModalOpenCheckEmailProps } from "./Modal.types";
import style from "./Modal.module.css";

const CheckYourEmail: React.FC<ModalOpenCheckEmailProps> = ({
  newPassModalHandler,
  checkEmailModalHandler,
}): JSX.Element => {
  const openNewPassModal = () => {
    checkEmailModalHandler(false);
    newPassModalHandler(true);
  };

  return (
    <div className={style.modal}>
      <div className={style.checkEmailContentWrap}>
        <h2 className={style.title}>Перевірте пошту</h2>
        <p className={style.forggotenPassText}>
          Перейдіть до листа, який ми надіслали вам на електронну пошту та
          введіть код
        </p>
        <div onClick={openNewPassModal}>
          <Button title={"Ввести код"} modal />
        </div>
      </div>
    </div>
  );
};

export default CheckYourEmail;
