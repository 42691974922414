import Button from "../../Common/Button/Button";
import style from "./Modal.module.css";
import { SuccessModalProps } from "./Modal.types";

const SignUpSuccessModal: React.FC<SuccessModalProps> = ({
  modalIsOpen,
  loginModalHandler,
}) => {
  return (
    <div className={style.modal}>
      <div className={style.successModalWrap}>
        <h2 className={style.title} style={{ marginBottom: "32px" }}>
          Реєстрація пройшла успішно
        </h2>
        <div
          onClick={() => {
            modalIsOpen(false);
            loginModalHandler(true);
          }}
        >
          <Button title={"Увійти"} modal />
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccessModal;
