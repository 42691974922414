import axios, { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Message } from "../../components/Common/Messages/types";
import { Admin, Page, User } from "./types";

axios.defaults.baseURL = "https://api.requiem.army/api";

// ==statistics==

export const getStatistic = createAsyncThunk(
  "admin/statistic",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get("/admins/statistic");
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==get all pages==

export const getAllPages = createAsyncThunk(
  "admin/pages",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get<Page[]>("/admins/pages");
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPageByIdForAdmin = createAsyncThunk(
  "admin/pages",
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get(`/admins/pages/${id}`);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==get all users==

export const getAllUsers = createAsyncThunk(
  "admin/users",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get<User[]>("/admins/users");
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==get moderators and admins==

export const getAdmins = createAsyncThunk(
  "admin/users",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get<Admin[]>("/admins");
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// user details==

export const getUserDetails = createAsyncThunk(
  "admin/user",
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get(`/admins/users/${id}`);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==get user status==

export const setUserStatus = createAsyncThunk(
  "admin/user",
  async (
    responseData: { id: string; status: "active" | "blocked" },
    thunkAPI
  ) => {
    const { id, status } = responseData;
    try {
      const { data } = await axios.patch(`/admins/users/${id}/status`, {
        status,
      });
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==get user role==

export const setUserRole = createAsyncThunk(
  "admin/user",
  async (
    responseData: { id: string; role: "user" | "moderator" },
    thunkAPI
  ) => {
    const { id, role } = responseData;
    try {
      const { data } = await axios.patch(`/admins/users/${id}/role`, {
        role,
      });
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==set page status==

export const setPageStatus = createAsyncThunk(
  "admin/user",
  async (responseData: { id: string; status: string }, thunkAPI) => {
    const { id, status } = responseData;
    try {
      const { data } = await axios.patch(`/admins/pages/${id}/status`, {
        status,
      });
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ==message==

export const sendMessageByAdmin = createAsyncThunk(
  "admin/message",
  async (responseData: { id: string; message: Message }, thunkAPI) => {
    const { id, message } = responseData;
    try {
      const { data } = await axios.post(`/admins/users/${id}/message`, message);
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        return thunkAPI.rejectWithValue(axiosError.response?.status);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);
