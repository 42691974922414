import React from "react";
import { TeamateCountType } from "./Teamate.types";
import style from "./Teamate.module.css";

const TeamateCount: React.FC<TeamateCountType> = ({ icon, role, count }) => {
  return (
    <div className={style.teamWrap}>
      <div className={style.teamIcon}>
        <img src={icon} alt={icon} />
      </div>
      <span className={style.teamRole}>{role}</span>
      <span className={style.teamCount}>{count}</span>
    </div>
  );
};

export default TeamateCount;
