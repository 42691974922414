import { useDispatch } from "react-redux";
import { signPolicy } from "../../../redux/User/slices/SignUpSlice";
import { AppDispatch } from "../../../redux/User/types";
import Button from "../../Common/Button/Button";
import Policy from "../../User/Policy/Policy";
import { PolicyModalProps } from "./Modal.types";
import style from "./Modal.module.css";

const PolicyModal: React.FC<PolicyModalProps> = ({
  modalIsOpen,
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <div className={style.modal}>
      <div className={style.policyContentWrap}>
        <h2 className={style.title}>
          <p>Політика</p>
          <p>конфіденційності</p>
        </h2>
        <div className={style.content}>
          <Policy modal={true} />
        </div>
      </div>
      <div
        onClick={() => {
          dispatch(signPolicy(true));
          modalIsOpen();
        }}
      >
        <Button title={"Погоджуюсь"} modal />
      </div>
    </div>
  );
};

export default PolicyModal;
