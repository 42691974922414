import { useCallback, useEffect, useState } from "react";
import style from "./Input.module.css";
import { emailPattern } from "../../../constants/patterns";
import { EmailProps } from "./Input.types";
import { getSignUpData } from "../../../redux/User/selectors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/User/types";
import { addEmail } from "../../../redux/User/slices/SignUpSlice";

const EmailInput: React.FC<EmailProps> = ({
  userError,
  setUserError,
  errorText,
  success,
  signUp,
  emptyEmail,
  setEmptyEmail,
  setServerError,
}) => {
  const signUpData = useSelector(getSignUpData);
  const dispatch: AppDispatch = useDispatch();

  const [email, setEmail] = useState(signUp ? signUpData.email : "");
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailValidateError, setEmailValidateError] = useState(false);
  const [emailLength, setEmailLength] = useState(false);

  const handleEmailSuccessValidate = useCallback(() => {
    setEmptyEmail(false);
    setEmailValidateError(false);
    setEmailLength(false);
    setCheckEmail(true);
  }, [setEmptyEmail]);

  const handleEmailValidate = useCallback(
    (value: string) => {
      if (value === "") {
        setEmptyEmail(true);
        setEmailValidateError(false);
        setEmailLength(false);
        return;
      } else {
        setEmptyEmail(false);
        handleEmailSuccessValidate();
      }

      const isValidEmail = emailPattern.test(value);

      if (!isValidEmail && value) {
        setEmptyEmail(false);
        setEmailValidateError(true);
        setEmailLength(false);
        return;
      } else {
        handleEmailSuccessValidate();
      }

      if (value.length > 50) {
        setEmptyEmail(false);
        setEmailValidateError(false);
        setEmailLength(true);
      } else {
        handleEmailSuccessValidate();
      }
      success(false);
    },
    [handleEmailSuccessValidate, success, setEmptyEmail]
  );

  useEffect(() => {
    if (signUpData.email && signUp) handleEmailValidate(signUpData.email);
  }, [signUpData, handleEmailValidate, signUp]);

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    success(true);
    if (signUp) dispatch(addEmail(e.target.value));
    setEmail(e.target.value.trim());
    handleEmailValidate(e.target.value.trim());
  };

  return (
    <>
      <label htmlFor="loginEmail" className={style.formLabel}>
        Електронна пошта
        <input
          autoComplete="on"
          id="loginEmail"
          className={style.formInput}
          type="email"
          value={email}
          onChange={(e) => {
            if (setUserError) setUserError(null);
            setServerError(false);
            handleEmailInputChange(e);
          }}
          name="email"
          placeholder="Введіть свою електронну адресу"
          required
          style={
            emptyEmail || emailValidateError || emailLength || userError
              ? { borderColor: "#86181e", color: "#86181e" }
              : checkEmail
              ? { borderColor: "#009955" }
              : { borderColor: "#93999b" }
          }
        />
      </label>
      {emptyEmail && (
        <p className={style.errorMessage}>Це поле є обов'язковим</p>
      )}
      {userError && <p className={style.errorMessage}>{errorText}</p>}
      {emailValidateError && (
        <p className={style.errorMessage}>Введіть коректну пошту</p>
      )}
      {emailLength && (
        <p className={style.errorMessage}>Не більше 50 символів</p>
      )}
    </>
  );
};

export default EmailInput;
