import { useState } from "react";
import {
  includeNumberPattern,
  passwordPattern,
  upperCasePattern,
} from "../../../constants/patterns";
import icons from "../../../assets/icons.svg";
import { PasswordProps } from "./Input.types";
import style from "./Input.module.css";

const PasswordInput: React.FC<PasswordProps> = ({
  userError,
  setUserError,
  success,
  emptyPassword,
  setEmptyPassword,
  setServerError,
}) => {
  const [passVisibility, setPassVisibility] = useState(true);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const [passLatin, setPassLatin] = useState(false);
  const [includeNumber, setIncludeNumber] = useState(false);
  const [uppercaseInclude, setUppercaseInclude] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [errorValidatePassword, setErrorValidatePassword] = useState(false);

  const handlePasswordValidate = (value: string) => {
    const isEmpty = handleEmptyPasswordValidate(value);
    const isLatin = handleIsLatinValidate(value);
    const includeNumber = handNumberIncludeValidate(value);
    const uppercaseInclude = handleUppercaseIncludeValidate(value);
    const passwordLengthValidate = handlePasswordLengthValidate(value);
    if (
      !isEmpty &&
      !isLatin &&
      !includeNumber &&
      !uppercaseInclude &&
      passwordLengthValidate
    ) {
      setErrorValidatePassword(false);
      success(false);
    }
  };

  const handleEmptyPasswordValidate = (value: string) => {
    if (value === "" && password === "") {
      setEmptyPassword(true);
      setErrorValidatePassword(true);
      return true;
    } else {
      setEmptyPassword(false);
      return false;
    }
  };

  const handleIsLatinValidate = (value: string) => {
    const isValidPass = passwordPattern.test(value);
    if (!isValidPass) {
      setPassLatin(true);
      setErrorValidatePassword(true);
      return true;
    } else {
      setPassLatin(false);
      return false;
    }
  };

  const handNumberIncludeValidate = (value: string) => {
    const isIncludeNumber = includeNumberPattern.test(value);
    if (!isIncludeNumber) {
      setIncludeNumber(true);
      setErrorValidatePassword(true);
      return true;
    } else {
      setIncludeNumber(false);
      return false;
    }
  };

  const handleUppercaseIncludeValidate = (value: string) => {
    const isIncludeUppercasePass = upperCasePattern.test(value);
    if (!isIncludeUppercasePass) {
      setUppercaseInclude(true);
      setErrorValidatePassword(true);
      return true;
    } else {
      setUppercaseInclude(false);
      return false;
    }
  };

  const handlePasswordLengthValidate = (value: string) => {
    if (value.length >= 8 && value.length <= 20) {
      setPasswordLength(true);
      setErrorValidatePassword(true);
      return true;
    } else {
      setPasswordLength(false);
      return false;
    }
  };

  const handlePasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    success(true);
    setPassword(e.target.value.trim());
    handlePasswordValidate(e.target.value);
    setCheckPassword(true);
  };

  return (
    <>
      <label htmlFor="loginPassword" className={style.formLabel}>
        Пароль
        <input
          autoComplete="on"
          id="loginPassword"
          className={style.formInput}
          value={password}
          onChange={(e) => {
            if (setUserError) setUserError(null);
            setServerError(false);
            handlePasswordInputChange(e);
          }}
          type={passVisibility ? "password" : "text"}
          style={
            errorValidatePassword || emptyPassword || userError
              ? { borderColor: "#86181e", color: "#86181e" }
              : checkPassword
              ? { borderColor: "#009955" }
              : { borderColor: "#93999b" }
          }
          name="password"
          placeholder="Пароль має містити від 8 до 20 символів"
          required
        />
        <div className={`${style.icon} ${style.backIcon} `}>
          {passVisibility ? (
            <svg
              onClick={() => setPassVisibility(!passVisibility)}
              className={`${style.icon} ${style.activeIcon}`}
              width={24}
              height={24}
            >
              <use href={`${icons}#eye_close`} />
            </svg>
          ) : (
            <>
              <svg
                onClick={() => setPassVisibility(!passVisibility)}
                className={`${style.icon} ${style.activeIcon}`}
                width={24}
                height={24}
              >
                <use href={`${icons}#eye_open`} />
              </svg>
            </>
          )}
        </div>
      </label>
      {emptyPassword && (
        <p className={style.errorMessage}>Це поле є обов'язковим</p>
      )}
      {userError && <p className={style.errorMessage}>Дані введені невірно</p>}
      {password && (
        <ul className={style.errorsList}>
          <li
            className={
              !includeNumber
                ? `${style.errorItem}`
                : `${style.errorItem} ${style.error}`
            }
          >
            Пароль має містити мінімум одну цифру
          </li>
          <li
            className={
              !uppercaseInclude
                ? `${style.errorItem}`
                : `${style.errorItem} ${style.error}`
            }
          >
            Пароль має містити мінімум одну велику літеру
          </li>
          <li
            className={
              passwordLength
                ? `${style.errorItem}`
                : `${style.errorItem} ${style.error}`
            }
          >
            Пароль має містити від 8 до 20 символів
          </li>
          <li
            className={
              !passLatin
                ? `${style.errorItem}`
                : `${style.errorItem} ${style.error}`
            }
          >
            Пароль має бути латиницею (a-z)
          </li>
        </ul>
      )}
    </>
  );
};

export default PasswordInput;
