import roman from "../../../assets/roman.png";
import valerii from "../../../assets/valerii.png";
import natalia from "../../../assets/natalia.png";
import vitalii from "../../../assets/vitalii.png";
import oleksandra from "../../../assets/oleksandra.png";
import mariana from "../../../assets/mariana.png";
import anna from "../../../assets/anna.png";
import lubov from "../../../assets/lubov.png";
import yana from "../../../assets/yana.png";
import maria from "../../../assets/maria.png";

import pm from "../../../assets/PM.svg";
import devs from "../../../assets/developers.svg";
import qa from "../../../assets/QA.svg";
import designers from "../../../assets/designers.svg";

export const team = [
  {
    name: "Валерій",
    role: "Front-end Developer",
    link: "https://www.linkedin.com/in/valerii-pometun-front-end-developer/",
    photo: valerii,
  },
  {
    name: "Роман",
    role: "Front-end Developer",
    link: "https://www.linkedin.com/in/roman-mid/",
    photo: roman,
  },
  {
    name: "Наталія",
    role: "Back-end Developer",
    link: "https://www.linkedin.com/in/nataliia-shevchenko-js/",
    photo: natalia,
  },
  {
    name: "Віталій",
    role: "Back-end Developer",
    link: "https://www.linkedin.com/in/vitaliy-korostylov",
    photo: vitalii,
  },
  {
    name: "Олександра",
    role: "QA",
    link: "https://www.linkedin.com/in/oleksandra-dobrovolska/",
    photo: oleksandra,
  },
  {
    name: "Марʼяна",
    role: "QA",
    link: "https://www.linkedin.com/in/mariana-zaloha/",
    photo: mariana,
  },
  {
    name: "Анна",
    role: "UX-UI Designer",
    link: "https://www.linkedin.com/in/anna-opolska-57544a1b9/",
    photo: anna,
  },
  {
    name: "Любов",
    role: "UX-UI Designer",
    link: "https://www.linkedin.com/in/liubov-solomon/",
    photo: lubov,
  },
  {
    name: "Яна",
    role: "UX-UI Designer",
    link: "https://www.linkedin.com/in/jane-myr/",
    photo: yana,
  },
  {
    name: "Марія",
    role: "Project Manager",
    link: "https://www.linkedin.com/in/mariia-ozyrska-81920b198/",
    photo: maria,
  },
];

export const teamRole = [
  {
    role: "Project Manager",
    count: "1 member",
    icon: pm,
  },
  {
    role: "Developers",
    count: "4 members",
    icon: devs,
  },
  {
    role: "QA",
    count: "2 members",
    icon: qa,
  },
  {
    role: "Product Design",
    count: "3 members",
    icon: designers,
  },
];
