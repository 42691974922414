import React from "react";
import { Navigate } from "react-router-dom";
import { PrivateProps } from "./PrivateRoute.types";
import { useAuth } from "../../../hooks/isAuth";

const PrivateRoute: React.FC<PrivateProps> = ({
  component: Component,
  redirectTo = "/",
}) => {
  const { isLoggedIn, isRefreshing } = useAuth();
  const shouldRedirect = !isLoggedIn && !isRefreshing;

  return shouldRedirect ? <Navigate to={redirectTo} /> : <Component />;
};

export default PrivateRoute;
