import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  LogInResponse,
  Notification,
  SignUpResponse,
  UpdateUser,
  User,
} from "../../User/types";
import {
  refreshUser,
  updateUser,
  userLogIn,
  userLogOut,
  userSignUp,
  userVerify,
  removeUser,
  delayedRemoveUser,
  cancelRemoveUser,
  getNotifications,
} from "../../User/operations";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { handleRejected, handleRemoveFullfilled } from "../handlers";

const persistConfig = {
  key: "user",
  storage,
  whitelist: ["token"],
};

const initialState: User = {
  user: {
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    token: "",
    verify: false,
    notifications: [],
    role: "user",
    status: "",
    _id: "",
    deleteAt: "",
  },
  isLoggedIn: false,
  isRefreshing: false,
  isVerify: false,
  error: null,
  token: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addTokenFromServices(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    updateHasPassword(state, action: PayloadAction<boolean>) {
      state.user.hasPassword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        userSignUp.fulfilled,
        (state: User, action: PayloadAction<SignUpResponse>) => {
          state.user.email = action.payload.email;
          state.user.verify = action.payload.verify;
          state.user.firstName = action.payload.firstname;
          state.error = null;
          state.isVerify = action.payload.verify;
        }
      )
      .addCase(
        userLogIn.fulfilled,
        (state: User, action: PayloadAction<LogInResponse>) => {
          if (!action.payload.verify) {
            return;
          }
          state.user.role = action.payload.role;
          state.user.notifications = action.payload.notifications;
          state.user = action.payload;
          state.error = null;
          state.isLoggedIn = true;
          state.token = action.payload.token;
          state.isVerify = action.payload.verify;
        }
      )
      .addCase(userLogOut.fulfilled, handleRemoveFullfilled)
      .addCase(delayedRemoveUser.fulfilled, handleRemoveFullfilled)
      .addCase(removeUser.fulfilled, handleRemoveFullfilled)
      .addCase(
        userVerify.fulfilled,
        (state: User, action: PayloadAction<LogInResponse>) => {
          state.isVerify = action.payload.verify;
        }
      )
      .addCase(
        updateUser.fulfilled,
        (state: User, action: PayloadAction<UpdateUser>) => {
          state.user.firstName = action.payload.firstName;
          state.user.lastName = action.payload.lastName;
          state.user.middleName = action.payload.middleName;
          state.user.phone = action.payload.phone;
        }
      )
      .addCase(refreshUser.pending, (state: User) => {
        state.isRefreshing = true;
        state.isLoggedIn = true;
      })
      .addCase(
        refreshUser.fulfilled,
        (state: User, action: PayloadAction<LogInResponse>) => {
          state.user.role = action.payload.role;
          state.user.notifications = action.payload.notifications;
          state.user.lastName = action.payload.lastName || "";
          state.user.firstName = action.payload.firstName || "";
          state.user.middleName = action.payload.middleName || "";
          state.user.phone = action.payload.phone || "";
          state.user.verify = action.payload.verify || false;
          state.user.token = action.payload.token || "";
          state.user.email = action.payload.email || "";
          state.user._id = action.payload._id || "";
          state.error = null;
          state.isLoggedIn = true;
          state.isRefreshing = false;
          state.isVerify = action.payload.verify;
          state.user.hasPassword = action.payload.hasPassword;
          state.user.deleteAt = action.payload.deleteAt || "";
          state.user._id = action.payload._id;
          state.user.status = action.payload.status;
        }
      )
      .addCase(cancelRemoveUser.fulfilled, (state: User) => {
        state.isLoggedIn = true;
        state.user.deleteAt = "";
      })
      .addCase(
        getNotifications.fulfilled,
        (state: User, action: PayloadAction<Notification[]>) => {
          state.user.notifications = action.payload;
        }
      )
      .addCase(
        refreshUser.rejected,
        (state: User, action: PayloadAction<any>) => {
          state.isRefreshing = false;
          state.isLoggedIn = false;
          state.error = action.payload;
        }
      )
      .addCase(
        userLogOut.rejected,
        (state: User, action: PayloadAction<any>) => {
          state.isLoggedIn = false;
          state.error = action.payload;
          state.token = "";
        }
      )
      .addMatcher((action) => {
        return action.type.endsWith("/rejected");
      }, handleRejected);
  },
});

export const { addTokenFromServices, updateHasPassword } = userSlice.actions;

export const userReducer = persistReducer(persistConfig, userSlice.reducer);
