import { ButtonProps } from "./Button.type";
import style from "./Button.module.css";

const Button: React.FC<ButtonProps> = ({
  title,
  modal,
  removeProfile,
}): JSX.Element => {
  return (
    <button
      className={style.button}
      style={{
        height: modal ? "51px" : "",
        fontSize: modal ? "16px" : "",
        backgroundColor: removeProfile ? "#CA0000" : "",
        color: removeProfile ? "#F9F9F9" : "",
      }}
    >
      {title}
    </button>
  );
};

export default Button;
