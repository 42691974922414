export const namePattern = /^[а-яґєіїА-ЯҐЄІЇ' -]*$/;
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const passwordPattern =
  /^(?=.*[a-z])[a-zA-Z0-9!@#$%^&*()_+={}[\\\]"':;<>,.?/~`|-]+$/;
export const includeNumberPattern = /\d/;
export const upperCasePattern = /.*[A-Z].*/;
/* eslint-disable no-useless-escape */
export const urlPattern =
  /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
/* eslint-enable no-useless-escape */
export const languagePattern = /^[^a-zA-Z]*$/;
