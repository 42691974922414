import React from "react";
import Button from "../../Common/Button/Button";
import { ModalChangePassSuccessProps } from "./Modal.types";
import style from "./Modal.module.css";

const ChangedPassSuccess: React.FC<ModalChangePassSuccessProps> = ({
  loginModalHandler,
  changedPassModalHandler,
  finishChangePassword,
  servicesPassIsCompleted,
  closeModal,
}): JSX.Element => {
  const openLoginModal = () => {
    if (finishChangePassword) {
      closeModal();
    } else {
      loginModalHandler(true);
    }
    changedPassModalHandler(false);
  };

  return (
    <div className={style.modal}>
      <div className={style.successModalWrap}>
        <h2
          className={`${style.title}`}
          style={{ marginBottom: finishChangePassword ? "32px" : "" }}
        >
          {servicesPassIsCompleted
            ? "Пароль успішно створено!"
            : "Пароль успішно змінено!"}
        </h2>
        {!finishChangePassword && (
          <p className={style.forggotenPassText}>
            Тепер ви можете увійти до свого профілю
          </p>
        )}
        <div onClick={openLoginModal}>
          <Button modal title={finishChangePassword ? "Закрити" : "Увійти"} />
        </div>
      </div>
    </div>
  );
};

export default ChangedPassSuccess;
