import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DeletedPhotosType } from "./DeletedPhotosSlice.types";

const initialState: DeletedPhotosType = {
  deletedPhotosArr: [],
};

const deletedPhotosSlice = createSlice({
  name: "deletedPhotos",
  initialState,
  reducers: {
    addDeletedPhoto(state, action: PayloadAction<string>) {
      state.deletedPhotosArr.push(action.payload);
    },
    clearDeletedPhotosArr(state) {
      state.deletedPhotosArr = [];
    },
  },
});

export const deletedPhotos = deletedPhotosSlice.reducer;
export const { addDeletedPhoto, clearDeletedPhotosArr } =
  deletedPhotosSlice.actions;
