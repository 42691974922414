import React from "react";
import { createPortal } from "react-dom";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../../utils/scrollToTop";
import icons from "../../../assets/icons.svg";
import style from "./Footer.module.css";
import TeamPage from "../../../pages/Common/TeamPage/TeamPage";

const modalElement = document.querySelector("#modal-root");

const Footer: React.FC = (): JSX.Element => {
  const [showTeamPage, setShowTeamPage] = React.useState<boolean>(false);

  const openTeamPage = () => {
    if (showTeamPage) {
      setShowTeamPage(false);
      document.body.classList.remove("lock");
    } else {
      setShowTeamPage(true);
      document.body.classList.add("lock");
    }
  };

  const handleActiveElementClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    event.currentTarget.blur();
  };

  const location = useLocation();
  const isProfile = location.pathname.includes("user");

  const wrapperStyle = isProfile
    ? `${style.wrapper} ${style.profileWrapper}`
    : `${style.wrapper}`;

  const teamPageItem = createPortal(
    <TeamPage setShowTeamPage={setShowTeamPage} showTeamPage={showTeamPage} />,
    modalElement as Element
  );

  return (
    <>
      <footer className={wrapperStyle}>
        <div className={`${style.inner} container`}>
          <Link
            className={style.logoLink}
            to="/"
            onClick={(e) => {
              handleActiveElementClick(e);
              scrollToTop();
            }}
            aria-label="Logo"
          >
            <svg className={style.logoIcon}>
              <use href={`${icons}#logo`} />
            </svg>
          </Link>
          <address>
            <ul className={style.addressList}>
              <li>
                <Link
                  to="mailto:requiem.ua.main@gmail.com"
                  className={style.link}
                  onClick={(e) => {
                    handleActiveElementClick(e);
                  }}
                >
                  requiem.ua.main@gmail.com
                </Link>
              </li>
              <li>
                <Link
                  to="tel:+380955418746"
                  className={style.link}
                  onClick={(e) => {
                    handleActiveElementClick(e);
                  }}
                >
                  +38 (095) 541-87-46
                </Link>
              </li>
            </ul>
          </address>
          <ul className={style.pages}>
            <li>
              <NavLink
                to="/about"
                className={style.link}
                onClick={(e) => {
                  handleActiveElementClick(e);
                  scrollToTop();
                }}
              >
                Про проєкт
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/frequently-asked-question"
                className={style.link}
                onClick={(e) => {
                  handleActiveElementClick(e);
                  scrollToTop();
                }}
              >
                Питання
              </NavLink>
            </li>
          </ul>
          <ul className={style.pages}>
            <li>
              <NavLink
                to="/privacy-and-policy"
                className={style.link}
                onClick={(e) => {
                  handleActiveElementClick(e);
                  scrollToTop();
                }}
              >
                Політика конфіденційності
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/terms"
                className={style.link}
                onClick={(e) => {
                  handleActiveElementClick(e);
                  scrollToTop();
                }}
              >
                Правила користування сайтом
              </NavLink>
            </li>
          </ul>
          <ul className={style.socialList}>
            <li>
              <Link
                className={style.icon}
                to="https://www.facebook.com/profile.php?id=61564992353790"
                target="_blank"
                aria-label="Facebook"
                onClick={(e) => {
                  handleActiveElementClick(e);
                }}
              >
                <svg className={style.socialIcon} width={24} height={24}>
                  <use href={`${icons}#facebook_footer`} />
                </svg>
              </Link>
            </li>
            <li>
              <Link
                className={style.icon}
                to="https://www.instagram.com/requiemarmy/?igsh=d2NuOWhpaWQ3dDJh&utm_source=qr"
                target="_blank"
                aria-label="Instagram"
                onClick={(e) => {
                  handleActiveElementClick(e);
                }}
              >
                <svg className={style.socialIcon} width={24} height={24}>
                  <use href={`${icons}#instagram`} />
                </svg>
              </Link>
            </li>
          </ul>
        </div>
        <p className={style.copyright}>
          Сайт створено
          <button
            className={`${style.copyrightlink} ${style.teamBtn}`}
            onClick={() => openTeamPage()}
          >
            командою
          </button>
          <Link
            className={style.copyrightlink}
            to="https://junfolio.top/"
            target="_blank"
            onClick={(e) => {
              handleActiveElementClick(e);
            }}
          >
            Junfolio
          </Link>
          {""} та
          <Link
            className={style.copyrightlink}
            to="https://webjungle.top/uk"
            target="_blank"
            onClick={(e) => {
              handleActiveElementClick(e);
            }}
          >
            WebJungle
          </Link>
          {""} за підтримки БФ «Кластер розвитку та допомоги» 2024 &#169; Всі
          права захищено.
        </p>
      </footer>
      {teamPageItem}
    </>
  );
};

export default Footer;
