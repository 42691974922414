import { StepsType } from "./StepsInfo.types";

export const steps: StepsType[] = [
  {
    number: 1,
    title: "Зареєструватись",
    shortText:
      "Щоб отримати можливість створити сторінку пам‘яті на нашому сайті необхідно пройти реєстрацію.",
    longText: `Щоб отримати можливість створити сторінку пам‘яті на нашому сайті необхідно пройти реєстрацію. 
              Після реєстрації у вас буде створено власний кабінет з можливістю створення сторінки пам‘яті загиблого героя.`,
    btn: "Зареєструватись",
  },
  {
    number: 2,
    title: "Додати документи",
    shortText: "Додайте документ, який підтверджує зв‘язок з загиблим героєм.",
    longText: `Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.`,
  },
  {
    number: 3,
    title: "Створити сторінку",
    shortText: "Створіть сторінку пам'яті, заповнивши всі необхідні поля.",
    longText: `Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.`,
  },
  {
    number: 4,
    title: "Згенерувати QR код",
    shortText:
      "Після погодження сторінки пам‘яті адміністратором буде згенеровано  QR код.",
    longText: `Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.`,
  },
  {
    number: 5,
    title: "Розмістити на могилі",
    shortText: "Завантажте QR код, надрукувавши, розмістіть на могилі.",
    longText: `Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.`,
  },
  {
    number: 6,
    title: "Сторінка доступна",
    shortText:
      "Відскануйте QR код за допомогою вашого телефону та отримайте доступ до сторінки загиблого героя.",
    longText: `Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.
            Створи сторінку на нашому сайті. Після цього появиться моживість створити сторінку пам’яті.`,
  },
];
