import { PayloadAction } from "@reduxjs/toolkit";
import { User } from "../User/types";

export const handleRejected = (state: User, action: PayloadAction<number>) => {
  state.error = action.payload;
  if (action.payload === 401) {
    state.token = "";
    state.user.token = "";
  }
};

export const handleRemoveFullfilled = (state: User) => {
  state.error = null;
  state.isLoggedIn = false;
  state.isRefreshing = false;
  state.token = "";
  state.user.token = "";
  state.user.role = "user";
};
