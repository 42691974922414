import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../redux/User/operations";
import { AppDispatch, ChangePasswordType } from "../../../redux/User/types";
import {
  includeNumberPattern,
  passwordPattern,
  upperCasePattern,
} from "../../../constants/patterns";
import Loading from "../../Common/Loading/Loading";
import Button from "../../Common/Button/Button";
import ServerError from "../../Common/ServerError/ServerError";
import ModalsWrapper from "./ModalsWrapper";
import icons from "../../../assets/icons.svg";
import { ModalCreateNewPassProps } from "./Modal.types";
import style from "./Modal.module.css";

const CreateNewPasswordModal: React.FC<ModalCreateNewPassProps> = ({
  createNewPassModalHandler,
  forgetPassModalHandler,
  changedPassModalHandler,
}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPassError, setOldPassError] = useState(false);
  const [emptyOldPassError, setEmptyOldPassError] = useState(false);
  const [oldPassVisability, setOldPassVisability] = useState(false);
  const [passVisability, setPassVisability] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [emptyPassError, setEmptyPassError] = useState(false);
  const [passLatinError, setPassLatinError] = useState(false);
  const [passIncludeNumberError, setPassIncludeNumberError] = useState(false);
  const [passIncCapLettersError, setPassIncCapLettersError] = useState(false);
  const [similarPasswordsError, setSimilarPasswordsError] = useState(false);
  const [repeatPassVisability, setrepeatPassVisability] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPassError, setRepeatPassError] = useState(false);
  const [emptyRepeatPassError, setEmptyRepeatPassError] = useState(false);

  const checkPasswords = (value: string, password: string) => {
    if (password === value && password !== "") {
      setSimilarPasswordsError(true);
    } else {
      setSimilarPasswordsError(false);
    }
  };

  // password validate

  const validatePassword = (value: string) => {
    setEmptyPassError(false);
    checkPasswords(value, oldPassword);

    if (value !== repeatPassword && repeatPassword) {
      setPasswordError(true);
      setRepeatPassError(true);
    } else {
      setRepeatPassError(false);
    }
    if (value.length < 8 || value.length > 20) {
      setPasswordError(true);
    }
    const isValidPass = passwordPattern.test(value);
    if (!isValidPass) {
      setPassLatinError(true);
      setPasswordError(true);
    } else {
      setPassLatinError(false);
    }
    const isIncludeNumber = includeNumberPattern.test(value);
    if (!isIncludeNumber) {
      setPassIncludeNumberError(true);
      setPasswordError(true);
    } else {
      setPassIncludeNumberError(false);
    }
    const inCapitalLetters = upperCasePattern.test(value);
    if (!inCapitalLetters) {
      setPassIncCapLettersError(true);
      setPasswordError(true);
    } else {
      setPassIncCapLettersError(false);
    }
    if (value === "") {
      setPasswordError(false);
    }
    if (
      value.length >= 8 &&
      isValidPass &&
      isIncludeNumber &&
      inCapitalLetters
    ) {
      setPasswordError(false);
    }
  };

  const validateRepeatPass = (value: string) => {
    if (password !== value) {
      setRepeatPassError(true);
    } else {
      setRepeatPassError(false);
    }
    if (value === "") {
      setRepeatPassError(false);
    }
    setEmptyRepeatPassError(false);
  };

  // submit

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const passwords: ChangePasswordType = {
      password: formData.get("oldPassword") as string,
      newPassword: formData.get("newPassword") as string,
      confirmNewPassword: formData.get("confirmPassword") as string,
    };

    if (oldPassword === "") setEmptyOldPassError(true);
    if (password === "") setEmptyPassError(true);
    if (repeatPassword === "") setEmptyRepeatPassError(true);

    if (
      oldPassword &&
      password &&
      repeatPassword &&
      !passwordError &&
      !repeatPassError &&
      !similarPasswordsError
    ) {
      submitForm(passwords);
    }
  };

  const submitForm = async (data: ChangePasswordType) => {
    setIsLoading(true);
    setServerError(false);
    try {
      const { payload } = await dispatch(changePassword(data));
      if (payload === 400) {
        setOldPassError(true);
        return;
      }
      if (payload === 404) {
        setServerError(true);
        return;
      }
      createNewPassModalHandler(false);
      changedPassModalHandler(true);
    } catch (error) {
      setServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgetPassword = () => {
    forgetPassModalHandler(true);
    createNewPassModalHandler(false);
  };

  const [openNewPasswordModalWrapper, setOpenNewPasswordModalWrapper] =
    useState(false);

  return (
    <>
      {openNewPasswordModalWrapper && (
        <ModalsWrapper
          openModalWrapperLogic={setOpenNewPasswordModalWrapper}
          openCreatePassModal
        />
      )}

      {isLoading && <Loading />}
      <div className={style.modal}>
        <div className={style.forgottenPassContentWrap}>
          <h2 className={`${style.title}`}>Змінити пароль</h2>
          <div className={style.forggotenPassText}></div>
          <form
            className={`${style.form} ${style.newPassForm}`}
            onSubmit={handleSubmitForm}
          >
            <ul className={`${style.inputList} ${style.newPasswordList}`}>
              {/* old password */}

              <li className={style.inputItem}>
                <label htmlFor="oldPassword" className={style.formLabel}>
                  Старий пароль
                  <input
                    type={oldPassVisability ? "text" : "password"}
                    id="oldPassword"
                    name="oldPassword"
                    autoComplete="on"
                    placeholder="Введіть старий пароль"
                    className={style.formInput}
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value.trim());
                      setEmptyOldPassError(false);
                      setOldPassError(false);
                      checkPasswords(e.target.value.trim(), password);
                    }}
                    style={
                      emptyOldPassError || oldPassError
                        ? { borderColor: "#681318" }
                        : undefined
                    }
                  />
                </label>
                <div className={`${style.icon} ${style.backIcon} `}>
                  {!oldPassVisability ? (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() => setOldPassVisability(!oldPassVisability)}
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_close`} />
                    </svg>
                  ) : (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() => setOldPassVisability(!oldPassVisability)}
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_open`} />
                    </svg>
                  )}
                </div>
                {emptyOldPassError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}
                {oldPassError && (
                  <p className={style.errorMessage}>Неправельний пароль</p>
                )}
                <button
                  type="button"
                  onClick={handleForgetPassword}
                  className={`${style.navigateBtn} ${style.newKey}`}
                >
                  Забули пароль?
                </button>
              </li>

              {/* new password */}

              <li className={style.inputItem}>
                <label htmlFor="newPassword" className={style.formLabel}>
                  Новий пароль
                  <input
                    id="newPassword"
                    name="newPassword"
                    autoComplete="on"
                    className={style.formInput}
                    type={passVisability ? "text" : "password"}
                    placeholder="Пароль має містити від 8 до 20 символів"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value.trim());
                      validatePassword(e.target.value.trim());
                    }}
                    style={
                      passwordError || similarPasswordsError
                        ? { borderColor: "#681318", color: "#681318" }
                        : !similarPasswordsError && password !== ""
                        ? { borderColor: "#009955" }
                        : undefined
                    }
                  />
                </label>
                <div className={`${style.icon} ${style.backIcon} `}>
                  {!passVisability ? (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() => setPassVisability(!passVisability)}
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_close`} />
                    </svg>
                  ) : (
                    <svg
                      className={`${style.icon} ${style.activeIcon}`}
                      onClick={() => setPassVisability(!passVisability)}
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_open`} />
                    </svg>
                  )}
                </div>
                {similarPasswordsError && (
                  <p className={style.errorMessage}>
                    Не повторюйте старий пароль
                  </p>
                )}
                {emptyPassError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}
              </li>

              {/* repeat password */}

              <li className={style.inputItem}>
                <label htmlFor="repeatPassword" className={style.formLabel}>
                  Повторити новий пароль
                  <input
                    disabled={
                      similarPasswordsError ||
                      passwordError ||
                      password.length === 0
                        ? true
                        : false
                    }
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="on"
                    className={style.formInput}
                    type={repeatPassVisability ? "text" : "password"}
                    placeholder="Пароль має містити від 8 до 20 символів"
                    value={repeatPassword}
                    onChange={(e) => {
                      setRepeatPassword(e.target.value.trim());
                      validateRepeatPass(e.target.value.trim());
                    }}
                    style={
                      passwordError
                        ? { borderColor: "#93999b", color: "#93999b" }
                        : repeatPassError
                        ? { borderColor: "#86181e", color: "#86181e" }
                        : repeatPassword !== "" && !repeatPassError
                        ? { borderColor: "#009955" }
                        : { borderColor: "#93999b", color: "#000" }
                    }
                  />
                </label>
                <div className={`${style.icon} ${style.backIcon} `}>
                  {!repeatPassVisability ? (
                    <svg
                      className={`${style.icon} ${style.backIconn} ${style.activeIcon}`}
                      onClick={() =>
                        setrepeatPassVisability(!repeatPassVisability)
                      }
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_close`} />
                    </svg>
                  ) : (
                    <svg
                      className={`${style.icon} ${style.backIconn} ${style.activeIcon}`}
                      onClick={() =>
                        setrepeatPassVisability(!repeatPassVisability)
                      }
                      width={20}
                      height={20}
                    >
                      <use href={`${icons}#eye_open`} />
                    </svg>
                  )}
                </div>
                {emptyRepeatPassError && (
                  <p className={style.errorMessage}>Це поле є обов'язковим</p>
                )}

                {/* errors list */}

                {password && (
                  <ul className={style.errorsList}>
                    <li
                      className={
                        !repeatPassError &&
                        password !== "" &&
                        repeatPassword !== ""
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Підтвердіть пароль</span>
                    </li>
                    <li
                      className={
                        !passIncludeNumberError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Пароль має містити мінімум одну цифру</span>
                    </li>
                    <li
                      className={
                        !passIncCapLettersError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Пароль має містити мінімум одну велику літеру</span>
                    </li>
                    <li
                      className={
                        password.length < 8 || password.length > 20
                          ? `${style.errorItem} ${style.error}`
                          : `${style.errorItem}`
                      }
                    >
                      <span>Пароль має містити від 8 до 20 символів</span>
                    </li>
                    <li
                      className={
                        !passLatinError
                          ? `${style.errorItem}`
                          : `${style.errorItem} ${style.error}`
                      }
                    >
                      <span>Пароль має бути латиницею (a-z)</span>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
            <Button modal title={"Змінити пароль"} />
          </form>
          {serverError && <ServerError />}
        </div>
      </div>
    </>
  );
};
export default CreateNewPasswordModal;
