import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addTimer } from "../../../redux/User/slices/SignUpSlice";
import { AppDispatch, ForgotPasswordType } from "../../../redux/User/types";
import { forgotPassword } from "../../../redux/User/operations";
import { getUserEmail } from "../../../redux/User/slices/CheckUserEmail";
import Loading from "../../Common/Loading/Loading";
import Button from "../../Common/Button/Button";
import ServerError from "../../Common/ServerError/ServerError";
import EmailInput from "../InputFields/EmailInput";
import { ModalFogetPassProps } from "./Modal.types";
import style from "./Modal.module.css";

const ForgetPassword: React.FC<ModalFogetPassProps> = ({
  checkEmailModalHandler,
  fogetPassModalHandler,
}): JSX.Element => {
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [userError, setUserError] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const openNewPassModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data: ForgotPasswordType = {
      email: formData.get("email") as string,
    };

    if (data.email === "") {
      setEmptyEmail(true);
      return;
    }

    if (!emailError && !emptyEmail) {
      checkUserEmail(data);
      dispatch(getUserEmail(data.email));
    }
  };

  const checkUserEmail = async (data: ForgotPasswordType) => {
    setIsLoading(true);
    setServerError(false);
    try {
      const { payload } = await dispatch(forgotPassword(data));
      if (payload === 400) {
        setUserError(payload);
        return;
      }
      if (payload === 404) {
        setServerError(true);
        return;
      }
      checkEmailModalHandler(true);
      fogetPassModalHandler(false);
      const currentDate = new Date();
      const seconds = Math.floor(currentDate.getTime() / 1000);
      dispatch(addTimer(seconds));
    } catch (error) {
      setServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className={style.modal}>
        <div className={style.forgottenPassContentWrap}>
          <h2 className={`${style.title}`}>Забули пароль? </h2>
          <p className={style.forggotenPassText}>
            Введіть електронну пошту, яка пов'язана з профілем на нашому сайті
          </p>
          <form noValidate className={style.form} onSubmit={openNewPassModal}>
            <ul className={`${style.inputList}`}>
              <li className={style.inputItem}>
                <EmailInput
                  userError={userError}
                  setEmptyEmail={setEmptyEmail}
                  errorText="Користувача не знайдено"
                  success={setEmailError}
                  emptyEmail={emptyEmail}
                  setUserError={setUserError}
                  setServerError={setServerError}
                />
              </li>
            </ul>
            <Button modal title={"Змінити пароль"} />
          </form>
          {serverError && <ServerError />}
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
