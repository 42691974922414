import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalProps } from "./Modal.types";
import ButtonCloseModal from "../ButtonCloseModal/ButtonCloseModal";
import style from "./Modal.module.css";

const modalRoot = document.querySelector("#modal-root");

const Modal: React.FC<ModalProps> = ({
  modalIsOpen,
  modalWrapperIsOpen,
  policyAndTerms,
  documentModal,
  children,
}): JSX.Element => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        if (policyAndTerms) {
          policyAndTerms();
        } else {
          modalIsOpen(false);
          modalWrapperIsOpen(false);
          document.body.classList.remove("lock");
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalIsOpen, modalWrapperIsOpen, policyAndTerms]);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget === event.target) {
      if (policyAndTerms) {
        policyAndTerms();
      } else {
        modalIsOpen(false);
        modalWrapperIsOpen(false);
        document.body.classList.remove("lock");
      }
    }
  };

  return createPortal(
    <div className={style.overlay} onClick={handleBackdropClick}>
      <div className={style.modal}>
        {!documentModal && (
          <ButtonCloseModal
            openModalWrapperLogic={modalWrapperIsOpen}
            setIsLoginModalOpen={modalIsOpen}
            policyAndTermsLogic={policyAndTerms}
          />
        )}
        {children}
      </div>
    </div>,
    modalRoot as Element
  );
};

export default Modal;
