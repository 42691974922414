export const timerActiveBtn = (
  seconds: number,
  disabledBtn: (arg: boolean) => void
) => {
  const timerBtn = setInterval(() => {
    seconds--;
    if (seconds === 0 || seconds < 0) {
      clearInterval(timerBtn);
      disabledBtn(false);
    }
  }, 1000);
};
