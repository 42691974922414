import React from "react";
import Teamate from "../../../components/Common/Teamate/Teamate";
import { team, teamRole } from "./team";
import TeamateCount from "../../../components/Common/Teamate/TeamateCount";
import icons from "../../../assets/icons.svg";
import style from "./TeamPage.module.css";
import ButtonCloseModal from "../../../components/UI/ButtonCloseModal/ButtonCloseModal";
import { TeamPageType } from "./TeamPage.types";

const TeamPage: React.FC<TeamPageType> = ({
  setShowTeamPage,
  showTeamPage,
}) => {
  React.useEffect(() => {
    const closePage = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        setShowTeamPage(false);
        document.body.classList.remove("lock");
      }
    };

    const clickOutTeamPage = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains(style.overlay) && showTeamPage) {
        setShowTeamPage(false);
        document.body.classList.remove("lock");
      }
    };

    window.addEventListener("click", clickOutTeamPage);
    window.addEventListener("keydown", closePage);

    return () => {
      window.removeEventListener("click", clickOutTeamPage);
      window.removeEventListener("keydown", closePage);
    };
  }, [showTeamPage, setShowTeamPage]);

  const teamateItems = team.map((persone, ind) => {
    return <Teamate key={ind} {...persone} />;
  });

  const teamateRoleItem = teamRole.map((team, ind) => {
    return <TeamateCount key={ind} {...team} />;
  });

  return (
    <>
      <div
        className={
          showTeamPage
            ? `${style.overlay}`
            : `${style.overlay} ${style.overlayHide}`
        }
      ></div>
      <div
        className={
          showTeamPage
            ? `${style.mainWrap}`
            : `${style.mainWrap} ${style.mainWrapHide}`
        }
      >
        <ButtonCloseModal
          className={style.closeTeamPage}
          openModalWrapperLogic={setShowTeamPage}
          setIsLoginModalOpen={setShowTeamPage}
          white
        />
        <div className={style.iconsWrap}>
          <ul className={style.iconsList}>
            <li className={style.icon}>
              <svg width={22} height={26}>
                <use href={`${icons}#teamMeeting`} />
              </svg>
            </li>
            <li className={style.icon}>
              <svg width={28} height={16}>
                <use href={`${icons}#teamCamera`} />
              </svg>
            </li>
            <li className={style.icon}>
              <svg width={28} height={25}>
                <use href={`${icons}#teamChat`} />
              </svg>
            </li>
            <li className={style.icon}>
              <svg width={28} height={25}>
                <use href={`${icons}#teamCalendar`} />
              </svg>
            </li>
          </ul>
        </div>

        <div className={style.meetingWrap}>
          <div className={style.textWrap}>
            <span className={style.meeting}>Meeting now...</span>
            <span className={style.requiem}>Requiem team project</span>
            <span className={style.year}>2024</span>
            <span className={style.project}>Командний проєкт </span>
          </div>
          <div className={style.teamateRoleWrap}>{teamateRoleItem}</div>
        </div>
        <div className={style.teamWrap}>{teamateItems}</div>
      </div>
    </>
  );
};

export default TeamPage;
