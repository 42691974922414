import React from "react";
import StepInfoBlock from "../StepInfoBlock/StepInfoBlock";
import soldier from "../../../assets/soldier.png";
import soldierTablet from "../../../assets/soldier-tablet.png";
import soldierMobile from "../../../assets/soldier-modile.png";
import { steps } from "./StepsInfoData";
import style from "./StepsInfo.module.css";

const StepsInfo: React.FC = (): JSX.Element => {
  const stepsInfo = steps.map((step) => {
    return <StepInfoBlock key={step.number} {...step} />;
  });

  return (
    <section className={style.section}>
      <div className="container">
        <div className={style.contentWrap}>
          <div className={style.stepsWrap}>
            <h3 className={style.title}>Як створити сторінку пам’яті?</h3>
            <div className={style.stepsBlockWrap}>{stepsInfo}</div>
          </div>
          <div className={style.imgWrap}>
            <picture className={style.img}>
              <source media="(min-width: 1440px)" srcSet={soldier} />
              <source media="(min-width: 768px)" srcSet={soldierTablet} />
              <img src={soldierMobile} alt="soldier" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepsInfo;
